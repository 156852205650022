import React, { useState } from 'react';
import bglogo from '../assets/images/logo.svg';
import '../assets/styles/onboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config.js';
import { useNavigate } from 'react-router-dom';

function OnBoarding() {
    const [form, setForm] = useState({
        username: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: ''
    });

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [conPasswordVisible, setConPasswordVisible] = useState(false);
    const [view, setView] = useState('login');
    const [verifyform, setVerifyForm] = useState({ otp: ['', '', '', ''] });
    const navigate = useNavigate(); 

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConPasswordVisibility = () => {
        setConPasswordVisible(!conPasswordVisible);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleSignup = async (e) => {
        e.preventDefault();

        try {

            if (!validateEmail(form.email)) {
                toast.error('Invalid email format!');
                return;
            }

            if (form.password !== form.confirmPassword) {
                toast.error('Password does not match');
                return;
            }

            const response = await fetch(`${config.apiBaseUrl}signup`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(form),
            });

            const result = await response.json();
            if (response.ok) {
                setForm({ username: '', email: '', mobile: '', password: '', confirmPassword: '' });
                let message = "";
                if (result.data[0].Status === 200) {
                    message = `"${result.data[0].Name}" updated successfully.`;
                    toast.success(message);
                    changeView('login');
                }
                else if (result.data[0].Status === 205) {
                    message = `"${result.data[0].Name}" already exist.`;
                    toast.warning(message);
                }

                console.log('Form submitted:', form);
            } else {
                alert('Error: ' + result.message);
            }
        } catch (error) {
            console.error(error);
            alert('An error occurred');
        }

    };

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${config.apiBaseUrl}login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: form.username, password: form.password }),
            });

            const result = await response.json();
            if (response.ok) {
                setForm({ username: '', email: '', mobile: '', password: '', confirmPassword: '' });
            
                let message = "";
                if (result.data[0].Status === 200) {
                    message = `"${result.data[0].Name}" logged in successfully.`;
                    toast.success(message);
            
                    // Assuming you meant to store a token or something similar
                    localStorage.setItem("authToken", result.data[0].Name);
            
                    setTimeout(() => {
                        navigate('/admin', { state: { userData: result.data[0] } });
                    }, 3000);
                } 
                else if (result.data[0].Status === 205) {
                    message = "You don't have an account";
                    toast.warning(message);
                }
            
                console.log('Form submitted:', form);
            } else {
                toast.error('Error: ' + result.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred');
        }
    };

    const handleOTPChange = (e, index) => {
        const newOtp = [...verifyform.otp];
        newOtp[index] = e.target.value;

        if (e.target.value.length === 1 && index < 3) {
            const nextInput = document.getElementById(`otp-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }

        setVerifyForm({ otp: newOtp });
    };

    const handleVerifyOtp = (e) => {
        e.preventDefault();
        const otpString = form.otp.join('');
        console.log(`Verifying OTP: ${otpString}`);
    };

    const changeView = (newView) => {
        setView(newView);
    };

    return (
        <div className="d-flex align-items-center FullHeight onboard-container">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Zoom}
            />
            <div className="d-flex align-items-center justify-content-center FullWidth FullHeight main-container" style={{ zIndex: "999" }}>
                <img className='bg-logo-cls maxFullHeight' src={bglogo} alt="bg-logo" />
                <div className="signup-container maxFullHeight">
                    {view === 'signup' && (
                        <div>
                            <h2 className="signup-header">Sign Up</h2>
                            <form className="form-container" style={{ color: "white" }}>
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">User name</label>
                                    <input
                                        type="text"
                                        className="form-control form-style-cls"
                                        id="username"
                                        name="username"
                                        placeholder='Enter name...'
                                        value={form.username}
                                        onChange={handleChange}
                                        maxLength={50}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control form-style-cls"
                                        id="email"
                                        name="email"
                                        placeholder='Enter email...'
                                        value={form.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="mobile" className="form-label">Mobile</label>
                                    <input
                                        type="tel"
                                        className="form-control form-style-cls"
                                        id="mobile"
                                        name="mobile"
                                        placeholder='Enter mobile number...'
                                        value={form.mobile}
                                        onChange={handleChange}
                                        maxLength="10"
                                        pattern="[0-9]{10}"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, '');
                                        }}
                                        required
                                    />
                                </div>

                                <div className="mb-3 password-input-container">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input
                                        type={passwordVisible ? 'text' : 'password'}
                                        className="form-control form-style-cls"
                                        id="password"
                                        name="password"
                                        placeholder='Test@123'
                                        value={form.password}
                                        onChange={handleChange}
                                        required
                                    />
                                    <span className="confirm-password-toggle" onClick={togglePasswordVisibility}>
                                        <FontAwesomeIcon className='eyepassword' icon={passwordVisible ? faEyeSlash : faEye} />
                                    </span>
                                </div>
                                <div className="mb-3 password-input-container">
                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                    <input
                                        type={conPasswordVisible ? 'text' : 'password'}
                                        className="form-control form-style-cls"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder='Test@123'
                                        value={form.confirmPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                    <span className="confirm-password-toggle" onClick={toggleConPasswordVisibility}>
                                        <FontAwesomeIcon className='eyepassword' icon={conPasswordVisible ? faEyeSlash : faEye} />
                                    </span>
                                </div>

                                <button type="submit" className="btn btn-primary signup-btn" onClick={handleSignup}>Sign Up</button>
                                <div className='footer-signup'>
                                    <h5 className='signfoot-header'>Already have an account?</h5>
                                    <button className='btn-link' type="button" onClick={() => changeView('login')}>Login</button>
                                </div>
                            </form>
                        </div>
                    )}

                    {view === 'login' && (
                        <div>
                            <h2 className="signup-header">Login</h2>
                            <form className="form-container" style={{ color: "white" }}>
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">User name</label>
                                    <input
                                        type="text"
                                        className="form-control form-style-cls"
                                        id="username"
                                        name="username"
                                        placeholder='Enter name...'
                                        value={form.username}
                                        onChange={handleChange}
                                        maxLength={50}
                                        required
                                    />
                                </div>
                                <div className="mb-1 password-input-container ">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input
                                        type={passwordVisible ? 'text' : 'password'}
                                        className="form-control form-style-cls"
                                        id="password"
                                        name="password"
                                        placeholder='Test@123'
                                        value={form.password}
                                        onChange={handleChange}
                                        required
                                    />
                                    <span className="confirm-password-toggle" onClick={togglePasswordVisibility}>
                                        <FontAwesomeIcon className='eyepassword' icon={passwordVisible ? faEyeSlash : faEye} />
                                    </span>
                                </div>
                                <div className="forgot-pwd d-flex">
                                    <button className='btn-link-new SMN_effect-73' type="button" onClick={() => changeView('forgot')}>Forgot Password?</button>
                                </div>

                                <button type="submit" className="btn btn-primary signup-btn" onClick={handleLogin} >Login</button>
                                <div className='footer-signup'>
                                    <h5 className='signfoot-header'>Don't have an account?</h5>
                                    <button className='btn-link' type="button" onClick={() => changeView('signup')}>Sign Up</button>
                                </div>
                            </form>
                        </div>
                    )}

                    {view === 'forgot' && (
                        <div>
                            <h2 className="signup-header">Forgot Password</h2>
                            <form className="form-container" style={{ color: "white" }}>
                                <div>
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>Enter your Email ID or Mobile number to reset your password</p>
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control form-style-cls forgot-mailnumber"
                                        id="email"
                                        name="email"
                                        value={form.email}
                                        onChange={handleChange}
                                        placeholder="Email/Mobile number"
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary signup-btn verify-btn" onClick={() => changeView('verify')} >Send verification</button>
                                <div className='footer-signup'>
                                    <h5 className='signfoot-header'>Back to</h5>
                                    <button className='btn-link' type="button" onClick={() => changeView('login')}>Login</button>
                                </div>
                            </form>
                        </div>
                    )}

                    {view === 'verify' && (
                        <div>
                            <h2 className="signup-header">Verify OTP</h2>
                            <form className="form-container" style={{ color: "white" }} onSubmit={handleVerifyOtp}>
                                <div>
                                    <p style={{ textAlign: "center", fontSize: "12px" }}>Enter 4 Digit one time password (OTP) We send to your registered Mobile number /Email </p>
                                </div>
                                <div className="otp-input-container mb-3">
                                    {verifyform.otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            className="otp-input"
                                            id={`otp-${index}`}
                                            maxLength="1" // Limit input to 1 character
                                            value={digit}
                                            onChange={(e) => handleOTPChange(e, index)}
                                        />
                                    ))}
                                </div>
                                <button type="submit" className="btn btn-primary signup-btn verify-btn">Verify OTP</button>
                                <div className='footer-signup'>
                                    <h5 className='signfoot-header'>Didn't receive the OTP?</h5>
                                    <button className='btn-link' type="button">Resend OTP</button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default OnBoarding;
