import React from 'react';

const Popup = ({ buttons, onButtonClick, children }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        {children}
        <div className="popup-buttons">
          {buttons.map((button, index) => (
            <button key={index} onClick={() => onButtonClick(button)} className={button === 'Ok, Sure' ? 'popup-button-ok popup-button' : 'popup-button-cancel popup-button'} >
              {button}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Popup;
