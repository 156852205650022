import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ruleset.css';
import config from '../config';
import search_icon from '../assets/images/search_icon.svg';
import { ScaleLoader } from 'react-spinners';
import no_data_rule from '../assets/images/no_data_rule.svg';
import ruleset_dot from '../assets/images/ruleset_dot.svg';
import AddEditRulePopup from './addeditruleset.js';
import CanvasWithPolygon from './view-canvas.js';

const RuleSet = ({ closePopup, pageName ,menuCollapse}) => {
  const [searchValue, setSearchValue] = useState('');
  const [imageFrame, setImageFrame] = useState([]);
  const [imageRule, setImageRule] = useState([]);
  const [imageEmpty, setImageEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const dropdownRefs = useRef([]);
  const [isModalVisible, setModalVisible] = useState(null);
  const [selectedImageFrame, setSelectedImageFrame] = useState("");
  const [selImgRule, setSelImgRule] = useState("");

  const getCameraImageFrame = useCallback(async () => {
    try {
      const trimmedSearchValue = searchValue.trim();
      const response = await fetch(`${config.apiBaseUrl}GetCameraImages`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ SearchedValue: trimmedSearchValue }),
      });

      const result = await response.json();
      if (response.ok && result.length > 0) {
        setImageFrame(result);
        setImageEmpty(false);
      } else {
        setImageEmpty(true);
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.error('Fetch error:', error);
      toast.error('Failed to fetch images. Please try again later.');
      setLoading(false);
    }
  }, [searchValue]);

  const fetchRulsetImage = useCallback(async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}FetchRulsetImage`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          pageName: pageName || "use case"
        }),
      });

      const result = await response.json();

      if (response.ok && result.length > 0) {
        setImageRule(result);
      } else {
        setImageRule([]);
        if(!response.ok) {
          console.error('Error: ' + result.message);
          setImageRule([]);
        }
      }

    } catch (error) {
      console.error('Fetch error:', error);
    }
  }, [pageName]);

  useEffect(() => {
    getCameraImageFrame();
    fetchRulsetImage();
  }, [getCameraImageFrame, fetchRulsetImage]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const toggleDOTDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRefs.current[openDropdownIndex] && !dropdownRefs.current[openDropdownIndex].contains(event.target)) {
        setOpenDropdownIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdownIndex]);

  const openAddRulesetPage = (sAction, objItem) => {
    let isValue = false;

    for(let i=0;i<imageRule.length;i++) {
      if(imageRule[i].camera_id === objItem.camera_id) {
        isValue = true;
      }

      if(sAction === "Edit") {
        setSelImgRule(imageRule[i]);
      }
    }
    
    if (sAction === "Edit" && !isValue) {
      toast.error("No rulesets appear.");
      return;
    }

    if (sAction === "Add" && isValue) {
      toast.error("Only one ruleset can be added to the combination of camera and use case.");
      return;
    }

    setModalVisible(sAction);
    setSelectedImageFrame(objItem);
  };

  const handleClosePopup = () => {
    setModalVisible(null);
    getCameraImageFrame();
    fetchRulsetImage();
  };

  return (
    <div className='FullWidth FullHeight'>
      <div className={menuCollapse && loading ? 'clockloader-fd loading-clock-coll-rs' : (loading ? 'clockloader-rs clock-ruleset-rs' : '')}>
        <ScaleLoader color="#3b77c6" loading={loading} className='loading-clock-rs' size={72} />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
      <div className='FullWidth rulset-body-rs'>
        <div className='serachicon-rs'>
          <img src={search_icon} alt="search_icon" className='search_icon' />
          <input
            className="search-filter-fd"
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
        {imageEmpty ? (
          <div className='imageframe-rs row nodata-div-rs'>
            <img src={no_data_rule} alt='no_data_rule' className='nodata-rs' />
          </div>
        ) : (
          <div className='imageframe-rs row'>
            {imageFrame.map((item, index) => (
              <div key={index} className='col-6 ruleset-body-rs' ref={(el) => (dropdownRefs.current[index] = el)}>
                <h6>{item.brand || 'Camera new'}</h6>
                <div className="dropdown three-dot-rs">
                  <button className="three-dots three-dots-btn-rs" onClick={() => toggleDOTDropdown(index)}>
                    <img src={ruleset_dot} alt="ruleset_dot" />
                  </button>
                  <div className={`dropdown-content-rs dropdown-content ${openDropdownIndex === index ? "show" : ""}`}>
                    <label className='ruleset-btn-rs addrule-btn-rs' onClick={() => openAddRulesetPage("Add", item)}>Add rule</label>
                    <label className='ruleset-btn-rs editrule-btn-rs' onClick={() => openAddRulesetPage("Edit", item)}>Edit rule</label>
                  </div>
                </div>
                <img src={item.image_frame} alt='camera_images' className='image-frame-rs' />
                {imageRule.map((ruleItem, ruleIndex) => (
                  ruleItem && ruleItem.points && ruleItem.img_width && ruleItem.img_height && item.camera_id === ruleItem.camera_id && (
                    <CanvasWithPolygon
                      points={ruleItem.points}
                      width={ruleItem.img_width}
                      height={ruleItem.img_height}
                    />
                  )
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className='FullWidth rulset-footer-rs'>
        <button className='ruleset-close-rs' onClick={closePopup}>
          Close
        </button>
      </div>
      {isModalVisible && (
        <AddEditRulePopup closePopup={handleClosePopup} type={isModalVisible} rowData={selectedImageFrame} imageRule={selImgRule} pageName={pageName} />
      )}
    </div>
  );
};

export default RuleSet;
