import React , { useState , useEffect} from 'react';
import PPE from './ppe-detection.js';
import CamTemper from './camera-temepring.js';

function OtherDetect({ onType , menuCollapse}) {
    const [pageName, setPageName] = useState('');

    useEffect(() => {
        setPageName(onType);
    }, [onType]); 

    return (
        <div className='FullWidth FullHeight'>
            {pageName === 'PPE' && <PPE pageName={pageName} menuCollapse={menuCollapse} />}
            {pageName === 'Cemara tempering' && <CamTemper pageName={pageName} menuCollapse={menuCollapse} />}
        </div>
    );
}

export default OtherDetect;