import React, {useState,useEffect} from 'react';
import Members from './members.js';
import Plan from './plan-request.js';

function Settings({ onType, menuCollapse }) {
    const [pageName, setPageName] = useState('');

    useEffect(() => {
        setPageName(onType);
    }, [onType]);

    return (
        <div className='FullWidth FullHeight'>
            {pageName === 'Members' && <Members pageName={pageName} menuCollapse={menuCollapse} />}
            {pageName === 'Plan' && <Plan pageName={pageName} menuCollapse={menuCollapse} />}
        </div>
    );
}

export default Settings;

