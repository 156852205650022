import React, { useEffect, useCallback, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import config from '../config.js';
import './usecase-livefeed.css';
import livefeed_empty from '../assets/images/livefeed_empty.jpg';
import { ScaleLoader } from 'react-spinners';

const LiveFeed = ({ closeLiveFeedPopup, pageName }) => {
    const [rulsetCamera, setRulesetCamera] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchLiveFeed = useCallback(async () => {
        try {
            const response = await fetch(`${config.apiBaseUrl}GetRulsetCamera`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    pageName: pageName || "use case"
                }),
            });

            const result = await response.json();

            if (response.ok && result.length > 0) {
                setRulesetCamera(result);
                setTimeout(() => {
                    setLoading(false);
                }, 5000);
            } else {
                console.error('Error: ' + result.message);
                setLoading(false);
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }
    }, [pageName]);

    useEffect(() => {
        fetchLiveFeed();
    }, [fetchLiveFeed]);

    return (
        <Dialog
            open={true}
            onClose={closeLiveFeedPopup}
            aria-labelledby="live-feed-title"
            disableEnforceFocus
            aria-hidden="false"
            sx={{
                "& .MuiDialog-paper": {
                    height: "76%",
                    width: "70%",
                    maxWidth: "70%",
                },
            }} >

            <DialogTitle id="live-feed-title">
                <span className="active-camheader-lc">Live Feed</span>
            </DialogTitle>
            <DialogContent>
                <div className={loading ? 'clockloader-fd clockload-lf' : ''}>
                    <ScaleLoader color="#3b77c6" loading={loading} className='loading-clock-fd' size={72} />
                </div>
                <div className='live-feed-body d-flex'>
                    {rulsetCamera.length > 0 ? (
                        rulsetCamera.map((item, index) => (
                            <div className='col-6 cam-div-lf' key={`${item.camera_name}-${index}`}>
                                <p className='live-vd-text text-doted'>{item.camera_name || "Live camera"}</p>
                                <img src={`${config.apiBaseUrlPy}video_feed/${item.camera_id}/${encodeURIComponent(pageName)}`} className='live-vd-lc' alt='active_video' />
                            </div>
                        ))
                    ) : (
                        <img src={livefeed_empty} alt="livefeed_empty" className='empty-result-lf' />
                    )}
                </div>
                <div className='footer-div-lf'>
                    <button
                        onClick={closeLiveFeedPopup}
                        className="add-cambtn-common cancel-cm"
                        aria-label="Close live feed"
                    >
                        Close
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LiveFeed;
