import React, { useState, useEffect, useCallback } from 'react';
import '../../assets/styles/face-detection.css';
import search_icon from '../../assets/images/search_icon.svg';
import "react-datepicker/dist/react-datepicker.css";
import assign_btn from '../../assets/images/assign_btn.svg';
import filter_by from '../../assets/images/filter_by.svg';
import dwn_report from '../../assets/images/dwn_report.svg';
import config from '../../config.js';
import { format } from 'date-fns';
import refresh from '../../assets/images/refresh.svg';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import downloadExcel from '../../vendor/download-excel.js';
import "react-datepicker/dist/react-datepicker.css";
import RuleSet from '../../vendor/ruleset.js';
import nodatalist from '../../assets/images/nodatalist.svg';
import FilterModal from '../../vendor/filter-modal.js';
import live from '../../assets/images/live.svg';
import LiveFeed from '../../vendor/usecase-livefeed.js';
import { ClockLoader } from 'react-spinners';

function FacialDetect({ pageName ,menuCollapse}) {
    const [searchValue, setSearchValue] = useState('');
    const [attendance, setAttendance] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);
    const [selectedRowData, setSelectedRowData] = useState(0);
    const [isInit, setisInit] = useState(true);
    const [isOpenRuleset, setIsOpenRuleset] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [filterParams, setFilterParams] = useState({});
    const [popupAction, setPopupAction] = useState(null);
    const [filterCount, setFilterCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleRowClick = useCallback((index, item) => {
        setSelectedRowIndex(index);
        setSelectedRowData(item);
    }, []);

    useEffect(() => {
        console.log(selectedRowData);
    }, [selectedRowData]);

    const fetchAttendanceData = useCallback(async () => {
        setLoading(true);
        try {
            const trimmedSearchValue = searchValue.trim();

            const response = await fetch(`${config.apiBaseUrl}GetAttendanceDetas`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SearchedValue: trimmedSearchValue || '',
                    Camera: filterParams.camera || '',
                    Department: filterParams.department || '',
                    Status: filterParams.status || '',
                    StartDate: filterParams.startDate || '',
                    EndDate: filterParams.endDate || '',

                }),
            });

            const result = await response.json();

            if (response.ok && result.length > 0) {
                console.log('Data:', result);
                setTotalCount(result.length);
                setAttendance(result);
                handleRowClick(0, result[0]);
            } else {
                setTotalCount(0);
                setAttendance([]);
                if (!response.ok) {
                    console.error('Error: ' + result.message);
                }
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [searchValue, filterParams, handleRowClick]);

    const itemsPerPage = 15;
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = attendance.slice(firstIndex, lastIndex);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        fetchAttendanceData();
    }, [fetchAttendanceData]);

    useEffect(() => {
        if (currentItems.length > 0 && isInit === true) {
            setSelectedRowIndex(0);
            setSelectedRowData(currentItems[0]);
            setisInit(false);
        }
    }, [currentItems, isInit]);

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const RefreshPage = useCallback(() => {
        setSearchValue('');
        fetchAttendanceData();
        setFilterParams({});
        setFilterCount(0);
    }, [fetchAttendanceData]);

    const handleDownloadReport = () => {
        const data = attendance;
        downloadExcel(data, "staff_attendance");
    };

    const closePopup = () => {
        setIsOpenRuleset(false);
    }

    const closeFilterPopup = async (objData, filterCount) => {
        if (filterCount === null || filterCount === undefined) {
            filterCount = 0;
        }

        setFilterCount(filterCount);
        setIsOpenFilter(false);
        setFilterParams(objData);
        fetchAttendanceData();
    };

    const closeLiveFeedPopup = () => {
        setPopupAction(null);
    }

    return (
        isOpenRuleset === true ? (
            <div className='FullWidth FullHeight facial-main'>
                <RuleSet closePopup={closePopup} pageName={pageName} menuCollapse={menuCollapse} />
            </div>
        )
            : (
                <>
                    <div className={menuCollapse && loading ? 'clockloader-fd loading-clock-coll-cm' : (loading ? 'clockloader-fd loading-clock-cm' : '')}>
                        <ClockLoader color="#3b77c6" loading={loading} className='loading-clock-fd' size={72} />
                    </div>
                    <div className='FullWidth FullHeight facial-main'>
                        <div className=' header-container-fd'>
                            <div className='col-4'>
                                <img src={search_icon} alt="search_icon" className='search_icon' />
                                <input className="search-filter-fd" type="search" placeholder="Search" value={searchValue} onChange={handleSearchChange} />
                            </div>
                            <div className='col-8 d-flex justify-content-end align-items-center pad-com-fd'>

                                <button type="submit" title='View live feed' onClick={() => setPopupAction(true)} className="datePick-div-fd live-feed-fd  mr-common-fd center-pos text-doted" >
                                    {windowWidth < 780 ? (
                                        <img src={live} alt='live' className='sortby_date-fd' />
                                    ) : (
                                        <>
                                            <img src={live} alt='live' className='sortby_date-fd' />
                                            <span title='Live feed' className='txt-span-fd'>Live</span>
                                        </>
                                    )}
                                </button>

                                {popupAction && (
                                    <LiveFeed closeLiveFeedPopup={closeLiveFeedPopup} pageName={pageName} />
                                )}

                                <button type="submit" title='Set rule' className="datePick-div-fd mr-common-fd center-pos text-doted" onClick={() => { setIsOpenRuleset(true); }}>
                                    {windowWidth < 780 ? (
                                        <img src={assign_btn} alt='sortby_date' className='sortby_date-fd' />
                                    ) : (
                                        <>
                                            <img src={assign_btn} alt='sortby_date' className='sortby_date-fd' />
                                            <span title='Assign ruleset' className='txt-span-fd'>Assign</span>
                                        </>
                                    )}
                                </button>

                                <div className='filter-button-fd'>
                                    <div className='filtercount-fd'>
                                        {filterCount}
                                    </div>
                                    <button type="submit" title='Filter by' className="datePick-div-fd mr-common-fd center-pos text-doted" onClick={() => { setIsOpenFilter(true); }} >
                                        {windowWidth < 780 ? (
                                            <img src={filter_by} alt='filter_by' className='sortby_date-fd' />
                                        ) : (
                                            <>
                                                <img src={filter_by} alt='filter_by' className='sortby_date-fd' />
                                                <span className='txt-span-fd'>Filter by</span>
                                            </>
                                        )}
                                    </button>
                                </div>

                                {isOpenFilter && (
                                    <FilterModal closeFilterPopup={closeFilterPopup} filterParams={filterParams} rowData={attendance} pageName={pageName} />
                                )}

                                <button type="button" title='Download report' className="down-report-fd datePick-div-fd mr-common-fd center-pos text-doted" onClick={handleDownloadReport} >
                                    {windowWidth < 780 ? (
                                        <img src={dwn_report} alt='dwn_report' className='sortby_date-fd' />
                                    ) : (
                                        <>
                                            <img src={dwn_report} alt='dwn_report' className='sortby_date-fd' />
                                            <span className='txt-span-fd'>Download</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className='body-fd FullWidth FullHeight'>
                            <div className='FullWidth FullHeight'>
                                <div className='FullWidth table-fd FullHeight'>
                                    <div className='FullWidth table-body'>
                                        <div className='d-flex row-header-fd'>
                                            <div className='table-colhd-cm table-snum-fd text-doted set-minwidth-sno-fd'>S.No</div>
                                            <div title='Employee id' className='table-colhd-cm table-col-fd text-doted set-minwidth-eid-fd'>Emp ID</div>
                                            <div title='Employee name' className='table-colhd-cm table-col-fd text-doted set-minwidth-col-fd'>Name</div>
                                            <div title='Camera name' className='table-colhd-cm table-col-fd text-doted set-minwidth-col-fd'>Department</div>
                                            <div title='Cam ID/In Time' className='table-colhd-cm table-col-fd text-doted set-minwidth-col-fd'>In Camera</div>
                                            <div title='Cam ID/In Time' className='table-colhd-cm table-col-fd text-doted set-minwidth-eid-fd'>In Time</div>
                                            <div title='Cam ID/Out Time' className='table-colhd-cm table-col-fd text-doted set-minwidth-col-fd'>Out Camera</div>
                                            <div title='Cam ID/Out Time' className='table-colhd-cm table-col-fd text-doted set-minwidth-eid-fd'>Out Time</div>
                                            <div title='Status' className='table-colhd-cm table-col-fd text-doted set-minwidth-col-fd'>Status</div>
                                            <div title='Rule set' className='table-colhd-cm table-col-fd border-right-cm text-doted set-minwidth-col-fd'>Date</div>
                                        </div>
                                        <div className='row-container-fd'>
                                            {totalCount === 0 ? (
                                                <div className='nodata-list-cm'>
                                                    <img src={nodatalist} alt='nodatalist' className='nodatalist-cm' />
                                                    <div className='no-item-cm'>
                                                        No data found ...!
                                                    </div>
                                                </div>
                                            ) : (
                                                currentItems.map((item, index) => (
                                                    <div key={item.id || index}
                                                        className={`d-flex row-body-cm ${index === selectedRowIndex ? 'selected-row' : ''}`}
                                                        onClick={() => handleRowClick(index, item)}>
                                                        <div title={item.id} className='table-snum-fd text-doted text-al-center set-minwidth-sno-fd'>
                                                            {firstIndex + index + 1}
                                                        </div>
                                                        <div title={item.members_id} className='table-col-fd text-doted set-minwidth-eid-fd'>
                                                            {item.members_id}
                                                        </div>
                                                        <div title={item.employee_name} className='table-col-fd text-doted set-minwidth-col-fd'>
                                                            {item.employee_name}
                                                        </div>
                                                        <div title={item.department} className='table-col-fd text-doted set-minwidth-col-fd'>
                                                            {item.department}
                                                        </div>
                                                        <div title={`${item.in_camera}`} className='table-col-fd text-doted d-flex set-minwidth-col-fd'>
                                                            {item.in_camera}
                                                        </div>
                                                        <div title={`${item.in_time}`} className='table-col-fd text-doted d-flex set-minwidth-eid-fd'>
                                                            {item.in_time}
                                                        </div>
                                                        <div title={`${item.out_camera}`} className='table-col-fd text-doted d-flex set-minwidth-col-fd'>
                                                            {item.out_camera}
                                                        </div>
                                                        <div title={`${item.out_time}`} className='table-col-fd text-doted d-flex set-minwidth-eid-fd'>
                                                            {item.out_time}
                                                        </div>
                                                        <div title={item.status} className='table-col-fd text-doted d-flex set-minwidth-col-fd'>
                                                            {item.status}
                                                        </div>
                                                        <div title={format(new Date(item.date_time), 'dd/MM/yyyy')} className='table-col-fd text-doted border-right-cm text-al-center set-minwidth-col-fd'>
                                                            {format(new Date(item.date_time), 'dd/MM/yyyy')}
                                                        </div>
                                                    </div>
                                                )))}
                                        </div>
                                    </div>
                                    <div className='d-flex row-footer-cm justify-content-between'>
                                        <div>
                                            <button type="submit" title='Reset page' onClick={RefreshPage} className="btn btn-secondary d-flex add-cam-cm ">
                                                <img src={refresh} alt='cam_delete' className='button-st-cm refresh-btn-cm' />
                                                <span className='refresh-txt-cm'>Reset</span>
                                            </button>
                                        </div>
                                        <div>
                                            <Stack spacing={2}>
                                                <Pagination
                                                    count={Math.ceil(attendance.length / itemsPerPage)}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    shape="rounded"
                                                />
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </>
            )

    );
}

export default FacialDetect;
