import React from 'react';
import ApexCharts from 'react-apexcharts';

const AttendanceChart = ({rowData}) => {
  const series = [
    rowData?.AbsentCount || 0,
    rowData?.LateComerCount || 0,
    rowData?.OnTimeCount || 0,
  ];
  
  const chartOptions = {
    chart: {
      type: 'donut',
      height: '100%', 
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        donut: {
          size: '70%',
        },
        stroke: {
          width: 4,
          colors: '#FFFFFF',
        },
      },
    },
    colors: ['#B51274', '#EBCC07', '#1264B5'],
    labels: ['Absent', 'Late Commer', 'On time'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      formatter: function(val, opts) {
        return `${val} - ${opts.w.globals.series[opts.seriesIndex]}`;
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <div
      id="app"
      style={{
        width: '100%',  
        height: '200px', 
        overflow: 'hidden', 
      }}
    >
      <ApexCharts
        options={chartOptions}
        series={series}
        type="pie"
        height="100%" 
      />
    </div>
  );
};

export default AttendanceChart;
