
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const downloadExcel = (attendance, fileName) => {
    const data = attendance;
    const file = fileName;

    const worksheet = XLSX.utils.json_to_sheet(data);

    const calculateColumnWidths = (data) => {
        const maxWidths = [];

        const headers = Object.keys(data[0] || {});
        headers.forEach((header, index) => {
            maxWidths[index] = header.length;
        });

        data.forEach((row) => {
            Object.values(row).forEach((value, index) => {
                const stringValue = value ? value.toString() : "";
                const length = stringValue.length;
                maxWidths[index] = Math.max(maxWidths[index] || 0, length);
            });
        });

        return maxWidths.map((width) => ({ wch: width + 6 }));
    };

    worksheet["!cols"] = calculateColumnWidths(data);

    Object.keys(worksheet).forEach((cellKey) => {
        if (cellKey[0] === "!") return;

        worksheet[cellKey].s = {
            alignment: { horizontal: "left" },
        };
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
        cellStyles: true,
    });

    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, `${file}.xlsx`);
};

export default downloadExcel;
