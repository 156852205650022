import React from 'react';
import ReactApexChart from 'react-apexcharts';

// Reusable Bar Chart Component
const VehicleInOutChart = ({ title, series, colors, height, legendPosition }) => {
  const options = {
    chart: {
      type: 'bar',
      height: height || 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'top',
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['9am-12pm', '12pm-3pm', '3pm-6pm', '6pm-9am'],
    },
    yaxis: {
      title: {
        text: 'Vehicle Count',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + 'vehicle';
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: legendPosition || 'right',
      markers: {
        width: 10,
        height: 10,
      },
    },
  };

  return (
    <div>
      {title && <h3>{title}</h3>}
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={height || 350} />
      </div>
    </div>
  );
};

// Main Component to Render Multiple Charts
const App = () => {
  return (
    <div>

      <VehicleInOutChart
        series={[
          { name: 'In', data: [30, 25, 15, 5] },
          { name: 'Out', data: [10, 20, 30, 45] },
        ]}
        colors={['#d92786', '#1d69b5']}
        height={250}
        legendPosition="center"
      />
    </div>
  );
};

export default App;
