import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config.js';
import '../assets/styles/livecamera.css';
import search_icon from '../assets/images/search_icon.svg';
import no_video from '../assets/images/no_video.png';
import live_camera from '../assets/images/live_camera.svg';
import { ScaleLoader } from 'react-spinners';

const LiveCamPage = ({ camData, closePopup }) => {
    const [activeVideos, setActiveVideos] = useState([]);
    const [activeDefaultVideos, setActiveDefaultVideos] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [totalCount, setTotalCount] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const RTSP_data = camData.map(cam => {
            let rtspCode = "";

            if (cam.rtsp_url === "") {
                const makeRTSP = "rtsp://" + cam.user_name + ":" + cam.password + "@" + cam.ip_address + ":554/cam/realmonitor?channel=1&subtype=0";
                rtspCode = encodeURIComponent(makeRTSP);
                return ({ camera_name: cam.camera_name, url: `${config.apiBaseUrlPy}add_feed/${rtspCode}` });
            }
            else {
                rtspCode = encodeURIComponent(cam.rtsp_url);
                return ({ camera_name: cam.camera_name, url: `${config.apiBaseUrlPy}add_feed/${rtspCode}` });
            }
        });
        setActiveVideos(RTSP_data);
        setActiveDefaultVideos(RTSP_data);
        setTotalCount(camData.length);
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, [camData]);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);

        if (value === "") {
            setActiveVideos(activeDefaultVideos);
            setTotalCount(activeDefaultVideos.length);
        } else {
            const results = activeDefaultVideos.filter(item => item.camera_name.toLowerCase().includes(value.toLowerCase()));
            setActiveVideos(results);
            setTotalCount(results.length);
        }
    };

    return (
        <Dialog open={true} onClose={closePopup} sx={{
            "& .MuiDialog-paper": {
              height: "76%",
              width: "70%",
              maxWidth: "70%",
            },}} >
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Zoom}
            />
            <DialogTitle>
                <span className='active-camheader-lc'>
                    <img src={live_camera} className='live_camera-fd' alt='live_camera' />
                    Active cameras
                </span>
            </DialogTitle>
            <DialogContent>
                <div className='body-container-lc'>
                    <div className={loading ? 'clockloader-fd' : ''}>
                        <ScaleLoader color="#3b77c6" loading={loading} className='loading-clock-fd' size={72} />
                    </div>
                    <div className='scheader-search-lc'>
                        <img src={search_icon} alt="search_icon" className='search_icon' />
                        <input
                            className="search-filter-cm"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                    </div>

                    {totalCount > 0 ? (
                        <div className='FullWidth d-flex cam-container-lc'>
                            {activeVideos.map((item, index) => (
                                <div className='col-6 cam-div-lc' key={`${item.camera_name}-${index}`}>
                                    <p className='live-vd-text text-doted'>{item.camera_name ||  "Live camera"}</p>
                                    <img src={item.url} className='live-vd-lc' alt='active_video' />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <img src={no_video} className='no_video-fd' alt="no_video" />
                    )}
                </div>
                <div className='close-lc'>
                    <button onClick={closePopup} className='add-cambtn-common cancel-cm'>Close</button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LiveCamPage;
