import React from 'react';

function Profile() {
    return (
        <div>
           
        </div>
    );
}

export default Profile;
