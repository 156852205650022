import React, { useState, useEffect } from 'react';
import './addeditruleset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import saverule from '../assets/images/saverule.svg';
import closerule from '../assets/images/closerule.svg';
import { toast } from 'react-toastify';
import config from '../config';
import CanvasWithSelectablePoints from './selected-canvas.js';
import axios from 'axios';

const AddEditRulePopup = ({ closePopup, type, rowData, imageRule, pageName }) => {
    const [points, setPoints] = useState([]);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [selectedOption, setSelectedOption] = useState("");
    const [isSelectOpen, setSelectIsOpen] = useState(false);
    const [isSelectTargetOpen, setSelectTargetOpen] = useState(false);
    const [selectPPEOpen, setSelectPPEOpen] = useState(false);
    const [imageHeight, setImageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [ruleName, setRuleName] = useState("");
    const [triggerCount, setTriggerCount] = useState(null);
    const [targetEntity, setTargetEntity] = useState(null);
    const [initialValues, setInitialValues] = useState(null);

    const options = [ "Main stream", "Sub stream", "Secondary stream"];
    const TargetOptions = ["Human", "Vehicle"];
    const PPEOptions = ["Helmet", "Gloves", "Jacket", "Glass"];

    useEffect(() => {
        if (type === "Edit" && imageRule) {
            setPoints(imageRule.points || []);
            setImageHeight(imageRule.img_height || 0);
            setImageWidth(imageRule.img_width || 0);
            setStartTime(imageRule.startTime || '');
            setEndTime(imageRule.endTime || '');
            setSelectedOption(imageRule.resolution || "");
            setRuleName(imageRule.rule_name || "");
            setTriggerCount(imageRule.trigger_count || null);
            setTargetEntity(imageRule.target_entity || null);

            setInitialValues({
                cameraId: rowData.camera_id,
                rule_name: imageRule.rule_name || "",
                useCase: pageName,
                resolution: imageRule.resolution || "",
                startTime: imageRule.start_time || '',
                endTime: imageRule.end_time || '',
                points: imageRule.points || [],
                imageHeight: imageRule.img_height || 0,
                imageWidth: imageRule.img_width || 0,
                trigger_count: imageRule.trigger_count || null,
                target_entity: imageRule.target_entity || null,
            });
        }

    }, [type, imageRule, pageName, rowData.camera_id]);

    useEffect(() => {
        if (type === "Edit" && imageRule) {
            setStartTime(imageRule.start_time ? convertTo24HourFormat(imageRule.start_time) : '');
            setEndTime(imageRule.end_time ? convertTo24HourFormat(imageRule.end_time) : '');
        }
    }, [type, imageRule]);

    const convertTo24HourFormat = (timeString) => {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);

        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setSelectIsOpen(false);
        setSelectTargetOpen(false);
        setSelectPPEOpen(false);
    };

    const handleTargetOptionClick = (option) => {
        setTargetEntity(option);
        setSelectIsOpen(false);
        setSelectTargetOpen(false);
        setSelectPPEOpen(false);
    };

    const handlePPETargetOptionClick = (option) => {
        setTargetEntity(option);
        setSelectIsOpen(false);
        setSelectTargetOpen(false);
        setSelectPPEOpen(false);
    };

    const handleStartChange = (event) => {
        const time = event.target.value;
        setStartTime(time);
    };

    const handleEndChange = (event) => {
        const time = event.target.value;
        setEndTime(time);
    };

    const handleRuleNameChange = (event) => {
        setRuleName(event.target.value);
    };

    const handleRuleCountChange = (event) => {
        setTriggerCount(event.target.value);
    };

    const handleSave = async () => {
        let apiUrl = "InsRulesetData";

        const ruleData = {
            cameraId: rowData.camera_id,
            rule_name: ruleName,
            useCase: pageName,
            resolution: selectedOption,
            startTime,
            endTime,
            points,
            imageHeight,
            imageWidth,
            trigger_count: triggerCount,
            target_entity: targetEntity
        };

        if (!ruleData.rule_name) {
            toast.error("All fields are required.");
            return;
        }
        if (!ruleData.resolution || ruleData.resolution === "Select resolution") {
            toast.error("All fields are required.");
            return;
        }
        if (!ruleData.startTime || !ruleData.endTime) {
            toast.error("All fields are required.");
            return;
        }
        if (ruleData.points.length === 0) {
            toast.error("You need to draw rulset.");
            return;
        }

        if (points.length !== 2 && points.length !== 4) {
            toast.error("Rule point should be 2 or 4.");
            return;
        }

        if (
            JSON.stringify(ruleData) === JSON.stringify(initialValues) // If no changes detected
        ) {
            toast.error("No changes found to save.");
            return;
        }

        let sendData = ruleData;

        if (type === "Edit") {
            const changedColumns = findChanges(ruleData);
            sendData = changedColumns[0];
            apiUrl = "UpdateRuleData";
        }

        try {
            const response = await fetch(`${config.apiBaseUrl}${apiUrl}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sendData),
            });

            const result = await response.json();
            if (response.ok) {
                const sucResult = "'" + result[0].rule_name + "' - ruleset saved successfully.";
                toast.success(sucResult);

                const pageUpdates = `${config.apiBaseUrlPy}pageupdates/ruleset/${type}/${rowData.camera_id}/${pageName}`;
                axios.post(pageUpdates).catch((error) => {
                    console.error('Error calling API:', error);
                });

                closePopup();
            } else {
                const error = await response.json();
                toast.error(`Error saving rule: ${error.message}`);
            }
        } catch (error) {
            console.error("Error saving rule:", error);
            toast.error("An error occurred while saving the rule.");
        }
    };

    const handlePointsChange = (points, height, width) => {
        if (points.length > 0) {
            setPoints(points);
            setImageHeight(height);
            setImageWidth(width);
        } else {
            setPoints(imageRule.points || []);
        }
    };

    const findChanges = (ruleData) => {
        const changes = Object.entries(ruleData)
            .filter(([key, newValue]) => newValue !== initialValues[key])
            .map(([key, newValue]) => ({ key, newValue, ruleset_id: imageRule.ruleset_id }));

        return changes;
    };

    return (
        <div className="modal fade show" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row-container FullWidth col-12">
                            <div className="col-6 main-bodycon-aers">
                                <div className="image-contain-aers" style={{ position: 'relative' }}>
                                    <img src={rowData.image_frame} alt="selected_image" className="selected-image-aers" />
                                    {type === "Edit" && imageRule && imageRule.points && imageRule.img_width && imageRule.img_height && rowData.camera_id === imageRule.camera_id ? (
                                        <CanvasWithSelectablePoints
                                            initialPoints={imageRule.points || []}
                                            width={imageRule.img_width}
                                            height={imageRule.img_height}
                                            onPointsChange={handlePointsChange}
                                        />
                                    ) : (
                                        <CanvasWithSelectablePoints
                                            initialPoints={[]}
                                            width={332}
                                            height={512}
                                            onPointsChange={handlePointsChange}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-6 FullHeight main-bodycon-aers">
                                <div className="form-container">
                                    <h5 className='header-aers'>{type === 'Add' ? 'Add New Rule' : 'Edit Rule'}</h5>
                                    <hr className='hr-aers' />
                                    <form className='form-aers'>
                                        <div className="form-group form-group-aers">
                                            <label className='form-label-aers'>Camera ID</label>
                                            <input className='input-value-aers' type="text" value={rowData.camera_id} readOnly />
                                        </div>
                                        <div className="form-group form-group-aers">
                                            <label className='form-label-aers'>Rule name <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                className='input-value-aers'
                                                type="text"
                                                placeholder="Enter Rule Name"
                                                value={ruleName}
                                                onChange={handleRuleNameChange}
                                            />
                                        </div>
                                        <div className="form-group form-group-aers">
                                            <label className='form-label-aers'>Use case</label>
                                            <input className='input-value-aers' type="text" value={pageName} readOnly />
                                        </div>
                                        <div className="form-group form-group-aers d-flex">
                                            <label className='form-label-aers resolution-aers'>Resolution <span style={{ color: "red" }}>*</span></label>
                                            <div className="custom-dropdown">
                                                <div
                                                    className={`dropdown-header ${isSelectOpen ? "open" : ""}`}
                                                    onClick={() => { setSelectIsOpen(!isSelectOpen); setSelectPPEOpen(false); setSelectTargetOpen(false); }}
                                                >
                                                    {selectedOption || "Select Resolution"}
                                                </div>
                                                {isSelectOpen && (
                                                    <ul className="dropdown-options">
                                                        {options.map((option, index) => (
                                                            <li
                                                                key={index}
                                                                className="dropdown-option"
                                                                onClick={() => handleOptionClick(option)}
                                                            >
                                                                {option}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>

                                        {pageName === "Tripwire" &&
                                            <div className="form-group form-group-aers d-flex mt-2">
                                                <label className='form-label-aers resolution-aers'>Target <span style={{ color: "red" }}>*</span></label>
                                                <div className="custom-dropdown">
                                                    <div
                                                        className={`dropdown-header ${isSelectTargetOpen ? "open" : ""}`}
                                                        onClick={() => { setSelectTargetOpen(!isSelectTargetOpen); setSelectPPEOpen(false); setSelectIsOpen(false); }}
                                                    >
                                                        {targetEntity || "Select target"}
                                                    </div>
                                                    {isSelectTargetOpen && (
                                                        <ul className="dropdown-options">
                                                            {TargetOptions.map((option, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="dropdown-option"
                                                                    onClick={() => handleTargetOptionClick(option)}
                                                                >
                                                                    {option}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        }

                                        {pageName === "Crowd detection" &&
                                            <div className="form-group form-group-aers">
                                                <label className='form-label-aers'>Target count</label>
                                                <input className='input-value-aers' type="text" value={triggerCount} onChange={handleRuleCountChange} />
                                            </div>
                                        }

                                        {pageName === "PPE" &&
                                            <div className="form-group form-group-aers d-flex mt-2">
                                                <label className='form-label-aers resolution-aers'>Target <span style={{ color: "red" }}>*</span></label>
                                                <div className="custom-dropdown">
                                                    <div
                                                        className={`dropdown-header ${selectPPEOpen ? "open" : ""}`}
                                                        onClick={() => { setSelectPPEOpen(!selectPPEOpen); setSelectIsOpen(false); setSelectTargetOpen(false); }}
                                                    >
                                                        {targetEntity || "Select type"}
                                                    </div>
                                                    {selectPPEOpen && (
                                                        <ul className="dropdown-options">
                                                            {PPEOptions.map((option, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="dropdown-option"
                                                                    onClick={() => handlePPETargetOptionClick(option)}
                                                                >
                                                                    {option}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        }

                                        <div className="form-group form-group-aers time-group col-12">
                                            <div className='time-st-aers'>
                                                <label className='form-label-aers time-label-aers'>Start time <span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    type="time"
                                                    onChange={handleStartChange}
                                                    value={startTime || ''}
                                                    className='input-value-aers'
                                                />
                                            </div>
                                            <div className='time-st-aers'>
                                                <label className='form-label-aers time-label-aers'>End time <span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    type="time"
                                                    onChange={handleEndChange}
                                                    value={endTime || ''}
                                                    className='input-value-aers'
                                                />

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary btn-close-aers btn-submit-aers" onClick={closePopup}>
                            <img src={closerule} alt="closerule" className="saverule-aers" />
                            Close
                        </button>
                        <button type="button" className="btn btn-primary btn-save-aers btn-submit-aers" onClick={handleSave}>
                            <img src={saverule} alt="saverule" className="saverule-aers" />
                            {type === 'Add' ? 'Save' : 'Update'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEditRulePopup;
