import React, { useState, useEffect } from "react";
import { ProSidebar, SidebarHeader, SidebarContent, SidebarFooter, Menu, MenuItem } from 'react-pro-sidebar';
import "react-pro-sidebar/dist/css/styles.css";
import classNames from 'classnames';
import arrow_left from '../assets/images/arrow_left.svg';
import arrow_right from '../assets/images/arrow_right.svg';
import logosidebar from '../assets/images/logo_sidebar.svg';
import logosidecollaps from '../assets/images/logo_sidecollaps.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from "./dashboard";
import Camera from "./camera";
import FaceDetect from "./face-detection/facedetection-main.js";
import PersonDetect from "./person-detection/persondetection-main.js";
import VehicleDetect from "../pages/vehicle-detection/vehicledetection-main.js";
/* import ObjectDetect from "./object-detection.js"; */
import OtherDetect from "./other-detection/otherdetection-main.js";
import Settings from "./settings";
import Profile from "./profile";
import FAQs from "./faqs";
import cam_select from "../assets/images/cam_select.svg";
import cam_unselect from "../assets/images/cam_unselect.svg";
import db_select from "../assets/images/db_select.svg";
import db_unselect from "../assets/images/db_unselect.svg";
import st_select from "../assets/images/st_select.svg";
import st_unselect from "../assets/images/st_unselect.svg";
import faq_select from "../assets/images/faq_select.svg";
import faq_unselect from "../assets/images/faq_unselect.svg";
import profile_select from "../assets/images/profile_select.svg";
import profile_unselect from "../assets/images/profile_unselect.svg";
import '../assets/styles/admin.css';
import mobile_user from '../assets/images/mobile_user.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import fd_select from '../assets/images/fd_select.svg';
import fd_unselect from '../assets/images/fd_unselect.svg';
import pd_unselect from '../assets/images/pd_unselect.svg';
import pd_select from '../assets/images/pd_select.svg';
import vd_select from '../assets/images/vd_select.svg';
import vd_unselect from '../assets/images/vd_unselect.svg';
import ot_select from '../assets/images/ot_select.svg';
import ot_unselect from '../assets/images/ot_unselect.svg';
import Popup from '../vendor/popup.js';
import face_att_sel from '../assets/images/face_att_sel.svg';
import face_att_usel from '../assets/images/face_att_usel.svg';
import unknown_sel from '../assets/images/unknown_sel.svg';
import unknown_usel from '../assets/images/unknown_usel.svg';
import pio_sel from '../assets/images/pio_sel.svg';
import pio_usel from '../assets/images/pio_usel.svg';
import crowd_sel from '../assets/images/crowd_sel.svg';
import crowd_usel from '../assets/images/crowd_usel.svg';
import gender_sel from '../assets/images/gender_sel.svg';
import gender_usel from '../assets/images/gender_usel.svg';
import trespass_sel from '../assets/images/trespass_sel.svg';
import trespass_usel from '../assets/images/trespass_usel.svg';
import trip_sel from '../assets/images/trip_sel.svg';
import trip_usel from '../assets/images/trip_usel.svg';
import vhino_sel from '../assets/images/vhino_sel.svg';
import vhino_usel from '../assets/images/vhino_usel.svg';
import anpr_sel from '../assets/images/anpr_sel.svg';
import anpr_usel from '../assets/images/anpr_usel.svg';
import ppe_usel from '../assets/images/ppe_usel.svg';
import ppe_sel from '../assets/images/ppe_sel.svg';
import camtemp_sel from '../assets/images/camtemp_sel.svg';
import camtemp_usel from '../assets/images/camtemp_usel.svg';
import members_sel from '../assets/images/members_sel.svg';
import members_usel from '../assets/images/members_usel.svg';
import plan_sel from '../assets/images/plan_sel.svg';
import plan_usel from '../assets/images/plan_usel.svg';

const Admin = () => {
  const location = useLocation();
  const userData = location.state?.userData;
  const currentPage = localStorage.getItem('currentPage');
  const currentSubPage = localStorage.getItem('currentSubPage');
  const [popupAction, setPopupAction] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [activePage, setActivePage] = useState(currentPage ? currentPage : 'Dashboard');
  const [activePageForSel, setActivePageForSel] = useState(currentPage ? currentPage : 'Dashboard');
  const [activeSubPage, setActiveSubPage] = useState(currentSubPage ? currentSubPage : '');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  const handleItemClick = (page) => {
    if (page === "Camera" || page === "Dashboard" || page === "FAQs") {
      setActivePage(page);
      setActiveSubPage('');
      localStorage.setItem('currentPage', page);
      localStorage.setItem('currentSubPage', '');
    }
    else {
      const currentPage = menuItems.find(item => item.name === page);
      setActiveSubPage(currentPage.subMenuItems[0].name);
      localStorage.setItem('currentPage', page);
      localStorage.setItem('currentSubPage', currentPage.subMenuItems[0].name);
    }


    setActivePageForSel(page);
  };

  const handleSubItemClick = (page) => {
    setActiveSubPage(page);
  }

  const menuItems = [
    { name: 'Dashboard', icons: { select: db_select, unselect: db_unselect } },
    { name: 'Camera', icons: { select: cam_select, unselect: cam_unselect } },
    {
      name: 'Face detection',
      icons: { select: fd_select, unselect: fd_unselect },
      subMenuItems: [
        { name: 'Facial attendance', icons: { select: face_att_sel, unselect: face_att_usel } },
        { name: 'Unknown person', icons: { select: unknown_sel, unselect: unknown_usel } }
      ],
      page: <FaceDetect onType={activeSubPage} menuCollapse={menuCollapse} />
    },
    {
      name: 'Person detection',
      icons: { select: pd_select, unselect: pd_unselect },
      subMenuItems: [
        { name: 'Poeple in/out', icons: { select: pio_sel, unselect: pio_usel } },
        { name: 'Crowd detection', icons: { select: crowd_sel, unselect: crowd_usel } },
        { name: 'Gender detection', icons: { select: gender_sel, unselect: gender_usel } },
        { name: 'Trespassing', icons: { select: trespass_sel, unselect: trespass_usel } },
        { name: 'Tripwire', icons: { select: trip_sel, unselect: trip_usel } }
      ],
      page: <PersonDetect onType={activeSubPage} menuCollapse={menuCollapse} />
    },
    {
      name: 'Vehicle detection',
      icons: { select: vd_select, unselect: vd_unselect },
      subMenuItems: [
        { name: 'Vehicle in/out', icons: { select: vhino_sel, unselect: vhino_usel } },
        { name: 'ANPR', icons: { select: anpr_sel, unselect: anpr_usel } }
      ],
      page: <VehicleDetect onType={activeSubPage} menuCollapse={menuCollapse} />
    },
    /* { name: 'Object detection', icons: { select: od_select, unselect: od_unselect }, page: <ObjectDetect /> }, */
    {
      name: 'Other detection', icons: { select: ot_select, unselect: ot_unselect },
      subMenuItems: [
        { name: 'PPE', icons: { select: ppe_sel, unselect: ppe_usel } },
        { name: 'Cemara tempering', icons: { select: camtemp_sel, unselect: camtemp_usel } },
      ],
      page: <OtherDetect onType={activeSubPage} menuCollapse={menuCollapse} />
    },
    {
      name: 'Settings',
      icons: { select: st_select, unselect: st_unselect },
      subMenuItems: [
        { name: 'Members', icons: { select: members_sel, unselect: members_usel } },
        { name: 'Plan', icons: { select: plan_sel, unselect: plan_usel } }
      ],
      page: <Settings onType={activeSubPage} menuCollapse={menuCollapse}/>
    },
    { name: 'FAQs', icons: { select: faq_select, unselect: faq_unselect } },
  ];

  const renderActivePage = () => {
    if (activeSubPage !== "") {
      let foundPage = null;
      for (let i = 0; i < menuItems.length; i++) {
        if (menuItems[i].subMenuItems !== undefined) {
          for (let j = 0; j < menuItems[i].subMenuItems.length; j++) {
            if (activeSubPage === menuItems[i].subMenuItems[j].name) {
              foundPage = menuItems[i].page;
              break;
            }
          }
        }
        if (foundPage) break;
      }
      return foundPage;
    }
    else {
      switch (activePage) {
        case 'Dashboard':
          return <Dashboard />;
        case 'Camera':
          return <Camera menuCollapse={menuCollapse} />;
        case 'Profile':
          return <Profile />;
        case 'FAQs':
          return <FAQs menuCollapse={menuCollapse}/>;
        default:
          return <Dashboard />;
      }
    }

  };

  const handleMBProfileClick = () => {
    setPopupAction(true);
  };

  // logout
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("currentSubPage");
    console.log("User logged out");
    navigate('/');
  };

  function getFormattedDate() {
    const today = new Date();

    const day = String(today.getDate()).padStart(2, '0'); // Get day with leading zero
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Get month with leading zero
    const year = String(today.getFullYear()).slice(2); // Get last two digits of the year
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayName = daysOfWeek[today.getDay()]; // Get day name

    return `${day}-${month}-${year} ${dayName}`; // Combine them
  };

  const handleButtonClick = (button) => {
    if (button === 'Ok, Sure') {
      handleLogout();
      setPopupAction(null);

    } else if (button === 'No, Cancel') {
      console.log('Cancel clicked');
      setPopupAction(null);
    }
  };

  return (
    <div id="header">
      {windowWidth <= 600 ? (
        <div className="bottom-bar">
          {menuItems.map(({ name, icons, label }) => (
            <div
              key={name}
              className={classNames('bottom-bar-item', {
                'active-item': activePage === name,
                'active-item-ml': menuCollapse,
              })}
              onClick={() => handleItemClick(name)}
            >
              <img src={activePage === name ? icons.select : icons.unselect} alt={`${name}_select`} />
              <span>{label}</span>
            </div>
          ))}
        </div>
      ) : (
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              {menuCollapse ? (
                <img src={logosidecollaps} alt="Collapsed Logo" />
              ) : (
                <img src={logosidebar} alt="Sidebar Logo" />
              )}
            </div>
          </SidebarHeader>

          <SidebarContent>
            <Menu iconShape="square">
              {menuItems.map(({ name, icons, subMenuItems }) => (
                <div key={name}>
                  <MenuItem
                    className={classNames('menu-item-transition', {
                      'active-item': activePageForSel === name,
                      'active-item-ml': menuCollapse,
                    })}
                    onClick={() => handleItemClick(name)}
                  >
                    <img
                      src={activePageForSel === name ? icons.select : icons.unselect}
                      alt={`${name}_select`}
                    />
                    {!menuCollapse && (
                      <p className={`text-sb-mm ${activePageForSel === name ? 'sb-select-mm' : ''}`}>
                        {name}
                      </p>
                    )}
                  </MenuItem>

                  {/* Dropdown Section for Face detection */}
                  {activePageForSel === name && subMenuItems && subMenuItems.length > 0 && (
                    <div className="subitem-cm">
                      {subMenuItems.map((subItem) => (
                        <div
                          key={subItem.name}
                          className={classNames('dropdown-item', {
                            'active-subitem': activeSubPage === subItem.name,
                            'active-subitem-icon': menuCollapse 
                          })}
                          onClick={() => handleSubItemClick(subItem.name)}
                        >
                          <img
                            src={activeSubPage === subItem.name ? subItem.icons.select : subItem.icons.unselect}
                            alt={`${subItem.name}_select`}
                          />

                          {!menuCollapse && (
                            <>
                              &nbsp;{subItem.name}
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </Menu>
          </SidebarContent>

          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem
                className={classNames('menu-item-transition', {
                  'active-item': activePage === 'Profile',
                  'active-item-ml': menuCollapse,
                })}
                onClick={() => setPopupAction(true)}
              >
                <img
                  src={activePage === 'Profile' ? profile_select : profile_unselect}
                  alt="profile_select"
                />
                {!menuCollapse && (
                  <div className="d-flex">
                    <p className={`text-sb-mm ${activePage === 'Profile' ? 'sb-select-mm' : ''}`}>Profile</p>
                  </div>
                )}
              </MenuItem>

              {popupAction && (
                <Popup buttons={['Ok, Sure', 'No, Cancel']} onButtonClick={handleButtonClick}>
                  <h5 className='dlt-cam-cm'>Logout</h5>
                  <p className='dlt-txt-cm'>Are you sure to logout this account ?</p>
                </Popup>
              )}
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      )}

      <div className={menuCollapse ? 'members-body collabs-body-mm' : 'members-body'}>
        <div className="body-header-mm">
          {windowWidth <= 600 ? (
            <div className="d-flex align-items-center FullWidth">
              <img src={logosidecollaps} alt="Sidebar Logo" />
              <h5 className="header-title-mm" >{activePage}</h5>
              <div
                className={'bottom-bar-item mobile-user'}
                onClick={() => handleMBProfileClick()}
              >
                <img
                  src={mobile_user}
                  alt="profile_select"
                  style={{ width: "35px" }}
                />
              </div>
            </div>
          ) : (
            <div
              className="closemenu d-flex"
              onClick={menuIconClick}
              role="button"
              tabIndex="0"
              aria-label="Toggle Sidebar"
            >
              {menuCollapse ? (
                <img src={arrow_right} className="com-width-mm" alt="arrow_right" />
              ) : (
                <img src={arrow_left} className="com-width-mm" alt="arrow_left" />
              )}

              {(activePage === "Dashboard" && !activeSubPage) ? (
                <div className="">
                  <h5 className="header-title-mm">Hello! {userData.Name}</h5>
                  <p className="date-formate-ad">{getFormattedDate()}</p>
                </div>
              ) : (
                <h5 className="header-title-mm">{activeSubPage === "" ? activePage : activeSubPage}</h5>
              )}
            </div>
          )}
        </div>
        <div className="body-container-mm">
          {renderActivePage()}
        </div>
      </div>
    </div >
  );
};

export default Admin;
