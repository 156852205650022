import React, { useState, useCallback, useEffect } from 'react';
import '../../assets/styles/vehicle-inout.css';
import filter_by from '../../assets/images/filter_by.svg';
import dwn_report from '../../assets/images/dwn_report.svg';
import assign_btn from '../../assets/images/assign_btn.svg';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import config from '../../config.js';
import nodatalist from '../../assets/images/nodatalist.svg';
import { format } from 'date-fns';
import refresh from '../../assets/images/refresh.svg';
import FilterModal from '../../vendor/filter-modal.js';
import downloadExcel from '../../vendor/download-excel.js';
import empty_captures from '../../assets/images/empty_captures.png';
import RuleSet from '../../vendor/ruleset.js';
import { ScaleLoader } from 'react-spinners';
import play_livefeed from '../../assets/images/play_livefeed.png';
import livedot from '../../assets/images/livedot.svg';
import { ClockLoader } from 'react-spinners';

function VehiclInOut({ pageName, menuCollapse }) {
    const [vehicleInOut, setVehicleInOut] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [clickedImage, setClickedImage] = useState('');
    const [clickedVideo, setClickedVideo] = useState('');
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);
    const [selectedRowData, setSelectedRowData] = useState(0);
    const [isInit, setisInit] = useState(true);
    const [isCaptureModalOpen, setIsCaptureModalOpen] = useState(false);
    const [isLiveModalOpen, setIsLiveModalOpen] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [filterParams, setFilterParams] = useState({});
    const [isOpenRuleset, setIsOpenRuleset] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const handleRowClick = useCallback((index, item) => {
        setSelectedRowIndex(index);
        setSelectedRowData(item);
    }, []);

    const fetchVehicleInOutDatas = useCallback(async (isReset) => {
        try {
            setLoading(true);
            const response = await fetch(`${config.apiBaseUrl}GetVehicleDatas`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Camera: isReset === 1 ? '' : (filterParams.camera || ''),
                    StartDate: isReset === 1 ? '' : (filterParams.startDate || ''),
                    EndDate: isReset === 1 ? '' : (filterParams.endDate || ''),
                }),
            });

            const result = await response.json();

            if (response.ok && result.length > 0) {
                console.log('Data:', result);
                setTotalCount(result.length);
                setVehicleInOut(result);
                handleRowClick(0, result[0]);
            } else {
                setTotalCount(0);
                setVehicleInOut([]);
                if (!response.ok) {
                    console.error('Error: ' + result.message);
                }
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [handleRowClick, filterParams]);

    useEffect(() => {
        fetchVehicleInOutDatas(0);
    }, [fetchVehicleInOutDatas]);

    const itemsPerPage = 16;
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = vehicleInOut.slice(firstIndex, lastIndex);
    const uniqueCameras = [];
    currentItems.forEach(item => {
        if (!uniqueCameras.some(camera => camera.name === item.camera_name)) {
            uniqueCameras.push({ name: item.camera_name, image_frame: item.image_frame });
        }
    });

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        console.log(selectedRowData);
    }, [selectedRowData]);

    useEffect(() => {
        if (currentItems.length > 0 && isInit === true) {
            setSelectedRowIndex(0);
            setSelectedRowData(currentItems[0]);
            setisInit(false);
        }
    }, [currentItems, isInit]);

    const RefreshPage = () => {
        setFilterCount(0);
        setFilterParams({});
        fetchVehicleInOutDatas(1);
    };

    const openUnknownMember = (objItem) => {
        setClickedImage(objItem);
        setIsCaptureModalOpen(true);
    };

    const openLiveVideo = (objItem) => {
        if (objItem.rtsp_url !== "") {
            objItem.rtsp_url = encodeURIComponent(objItem.rtsp_url);
        }

        setClickedVideo(objItem.rtsp_url);
        setIsLiveModalOpen(true);
    }

    const closeModal = () => {
        setIsCaptureModalOpen(false);
        setIsLiveModalOpen(false);
    };

    const closeFilterPopup = async (objData, filterCount) => {
        if (filterCount === null || filterCount === undefined) {
            filterCount = 0;
        }

        setFilterCount(filterCount);
        setIsOpenFilter(false);
        setFilterParams(objData);
        fetchVehicleInOutDatas(0);
    };

    const handleDownloadReport = () => {
        const data = vehicleInOut;
        downloadExcel(data, "vehicle_inout_data");
    };

    const closePopup = () => {
        setIsOpenRuleset(false);
    }

    useEffect(() => {
        if (clickedVideo) {
            setIsLoading(true);

            setTimeout(() => {
                setIsLoading(false);
            }, 5000);
        }
    }, [clickedVideo]);

    return (
        isOpenRuleset === true ? (
            <div className='FullWidth FullHeight main-div-up'>
                <RuleSet closePopup={closePopup} pageName={pageName} menuCollapse={menuCollapse} />
            </div>
        )
            : (<>
                <div className={menuCollapse && loading ? 'clockloader-fd loading-clock-coll-cm' : (loading ? 'clockloader-fd loading-clock-cm' : '')}>
                    <ClockLoader color="#3b77c6" loading={loading} className='loading-clock-fd' size={72} />
                </div>
                <div className='FullWidth FullHeight main-div-up'>
                    <div className='FullWidth button-container-upt'>
                        <button type='submit' className='common-button-upt text-doted ' onClick={() => { setIsOpenRuleset(true); }} >
                            <img src={assign_btn} alt='assign' className='common-st-btnicon ' />
                            Assign
                        </button>

                        <div className='filter-button-fd'>
                            <div className='filtercount-fd'>
                                {filterCount}
                            </div>
                            <button type='submit' className='common-button-upt text-doted ' onClick={() => { setIsOpenFilter(true); }}>
                                <img src={filter_by} alt='assign' className='common-st-btnicon ' />
                                Filter by
                            </button>
                        </div>
                        {isOpenFilter && (
                            <FilterModal closeFilterPopup={closeFilterPopup} filterParams={filterParams} rowData={vehicleInOut} pageName={pageName} />
                        )}

                        <button type='submit' className='common-button-upt download-btn-upt text-doted ' onClick={handleDownloadReport}  >
                            <img src={dwn_report} alt='assign' className='common-st-btnicon' />
                            Download
                        </button>
                    </div>
                    <div className='main-container-up'>
                        <div className=' FullHeight main-ndiv-upt'>
                            <div className='FullWidth table-division-upt FullHeight'>
                                <div className='FullWidth table-fd FullHeight'>
                                    <div className='FullWidth table-body'>
                                        <div className='d-flex row-header-upt row-header-vio'>
                                            <div className='table-colhd-cm sno-colhd-upt text-doted sno-upt'>S.No</div>
                                            <div title='Camera' className='table-colhd-cm  table-colhd-upt name-colhd-upt text-doted '>Camera</div>
                                            <div title='Action' className='table-colhd-cm  table-colhd-upt name-colhd-upt text-doted entryType-vio'>Action</div>
                                            <div title='Action' className='table-colhd-cm  table-colhd-upt name-colhd-upt text-doted varient-vio'>Varient</div>
                                            <div title='Date time' className='table-colhd-cm table-colhd-upt date-colhd-upt text-doted border-right-cm '>Date time</div>
                                        </div>
                                        <div className='row-container-upt row-container-vio'>
                                            {totalCount === 0 ? (
                                                <div className='nodata-list-cm'>
                                                    <img src={nodatalist} alt='nodatalist' className='nodatalist-cm' />
                                                    <div className='no-item-cm'>
                                                        No data found ...!
                                                    </div>
                                                </div>
                                            ) : (
                                                currentItems.map((item, index) => (
                                                    <div key={item.id || index}
                                                        className={`d-flex row-body-cm ${index === selectedRowIndex ? 'selected-row' : ''}`}
                                                        onClick={() => handleRowClick(index, item)}>
                                                        <div title={item.id} className='table-snum-fd text-doted text-al-center set-minwidth-sno-fd sno-colhd-upt sno-upt'>
                                                            {firstIndex + index + 1}
                                                        </div>
                                                        <div title={item.camera_name} className='table-col-fd text-doted d-flex set-minwidth-col-fd date-colhd-upt table-colhd-upt'>
                                                            {item.camera_name}
                                                        </div>
                                                        <div title={item.entrytype} className='table-col-fd text-doted justify-content-center d-flex set-minwidth-col-fd date-colhd-upt table-colhd-upt entryType-vio'>
                                                            {item.entrytype}
                                                        </div>
                                                        <div title={item.vehicletype} className='table-col-fd text-doted justify-content-center d-flex set-minwidth-col-fd date-colhd-upt table-colhd-upt varient-vio'>
                                                            {item.vehicletype}
                                                        </div>
                                                        <div title={format(new Date(item.detection_time), 'dd/MM/yyyy HH:mm a')} className='table-col-fd text-doted d-flex set-minwidth-col-fd date-colhd-upt border-right-cm  table-colhd-upt'>
                                                            {format(new Date(item.detection_time), 'dd/MM/yyyy HH:mm a')}
                                                        </div>
                                                    </div>
                                                )))}
                                        </div>
                                    </div>
                                    <div className='d-flex row-footer-cm justify-content-between'>
                                        <div>
                                            <button type="submit" title='Reset page' onClick={RefreshPage} className="btn btn-secondary d-flex add-cam-cm ">
                                                <img src={refresh} alt='cam_delete' className='button-st-cm refresh-btn-cm' />
                                                <span className='refresh-txt-cm'>Reset</span>
                                            </button>
                                        </div>
                                        <div>
                                            <Stack spacing={2}>
                                                <Pagination
                                                    count={Math.ceil(vehicleInOut.length / itemsPerPage)}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    shape="rounded"
                                                />
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=' FullHeight main-div-upt'>
                            <div className='FullWidth img-container-up'>
                                <div className='livefeed-container-upt '>
                                    {uniqueCameras.map((item, index) => (
                                        <div className='live-feed-upt' title='Play live' key={`${index}`}
                                            onClick={() => openLiveVideo(item)} >
                                            <img src={play_livefeed} alt='play_livefeed' className='play-livefeed-pio' />
                                            <span className='blur-st'><img src={livedot} alt='livedot' className='livedot-st' /> <p className='color-livedot'>Live</p> </span>
                                            <img src={item.image_frame} alt="live_feed" className='live-feed-container' />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='FullWidth img-container-up pt-2'>
                                <h5 className='capture-upt'>Captures</h5>
                                <div className='livefeed-container-upt image-unknown-upt'>
                                    {totalCount === 0 ? (
                                        <div className='nodata-list-cm'>
                                            <img src={empty_captures} alt='empty_captures' className='empty_captures-upt' />
                                            <div className='no-item-cm'>
                                                No data found ...!
                                            </div>
                                        </div>
                                    ) : (
                                        currentItems.map((item, index) => (
                                            <div
                                                className='unkown-image-con-ipt'
                                                title='Unknown person'
                                                key={item.id || index}
                                                onClick={() => openUnknownMember(item)}
                                            >
                                                <img src={item.image_path} alt='unkown_image' className='unkown-image-upt' />
                                            </div>
                                        ))
                                    )}
                                </div>
                                {isCaptureModalOpen && (
                                    <div className="image-modal" onClick={closeModal}>
                                        <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
                                            <img src={clickedImage.image_path} alt="maximized_image" className="maximized-image" />
                                        </div>
                                    </div>
                                )}

                                {isLiveModalOpen && (
                                    <div className="image-modal" onClick={closeModal}>
                                        <div className={isLoading ? 'loading-pio clockloader-fd loading-clock-aec' : ''}>
                                            <ScaleLoader color="#3b77c6" loading={isLoading} className='loading-clock-fd' size={72} />
                                        </div>
                                        <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
                                            <img src={`${config.apiBaseUrlPy}add_feed/${clickedVideo}`} alt="maximized_image" className="maximized-image" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )
    );
}

export default VehiclInOut;

