import React , { useState , useEffect} from 'react';
import PeopleInOut from './people-inout.js';
import CrowdDetection from './crowd-detection.js';
import GenderClassify from './gender-classify.js';
import TrespassDetect from './trespass-detect.js';
import TripwireDetect from './tripwire-detect.js';

function PersonDetect({ onType , menuCollapse}) {
    const [pageName, setPageName] = useState('');

    useEffect(() => {
        setPageName(onType);
    }, [onType]); 

    return (
        <div className='FullWidth FullHeight'>
            {pageName === 'Poeple in/out' && <PeopleInOut pageName={pageName} menuCollapse={menuCollapse} />}
            {pageName === 'Crowd detection' && <CrowdDetection pageName={pageName} menuCollapse={menuCollapse} />}
            {pageName === 'Gender detection' && <GenderClassify pageName={pageName} menuCollapse={menuCollapse} />}
            {pageName === 'Trespassing' && <TrespassDetect pageName={pageName} menuCollapse={menuCollapse} />}
            {pageName === 'Tripwire' && <TripwireDetect pageName={pageName} menuCollapse={menuCollapse} />}
        </div>
    );
}

export default PersonDetect;