import React, { useState, useEffect, useCallback } from 'react';
import '../assets/styles/camera.css';
import addcam from '../assets/images/addcam.svg';
import live from '../assets/images/live.svg';
import search_icon from '../assets/images/search_icon.svg';
import editcam from '../assets/images/editcam.svg';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { format } from 'date-fns';
import active_sign from '../assets/images/active_sign.svg';
import deactive_sign from '../assets/images/deactive_sign.svg';
import config from '../config.js';
import AddEditPagePopup from './addeditcamera.js';
import LiveCamPage from './livecameras.js';
import cam_delete from '../assets/images/cam_delete.svg';
import refresh from '../assets/images/refresh.svg';
import Popup from '../vendor/popup.js';
import nodatalist from '../assets/images/nodatalist.svg';
import axios from 'axios';
import { ClockLoader } from 'react-spinners';

const Camera = ({menuCollapse}) => {
    const [cameras, setCameras] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [activeCount, setActiveCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [popupAction, setPopupAction] = useState(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);
    const [selectedRowData, setSelectedRowData] = useState(0);
    const [isInit, setisInit] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleRowClick = useCallback((index, item) => {
        setSelectedRowIndex(index);
        setSelectedRowData(item);
    }, []);

    const fetchCameraDatas = useCallback(async () => {
        setLoading(true);
        try {
            const trimmedSearchValue = searchValue.trim();
            const response = await fetch(`${config.apiBaseUrl}GetCamDetas`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ SearchedValue: trimmedSearchValue }),
            });

            const result = await response.json();
            console.log('Result from API:', result);

            if (response.ok && result.length > 0) {
                console.log('Data:', result);
                handleRowClick(0, result[0]);
                setCameras(result);
                setActiveCount(result[0].ActiveCount);
                setTotalCount(result.length);
            } else {
                setActiveCount(0);
                setTotalCount(0);
                if (!response.ok) {
                    console.error('Error: ' + result.message);
                }
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [searchValue, handleRowClick]);


    const delCameraData = async () => {
        const pageUpdates = `${config.apiBaseUrlPy}pageupdates/cameras/delete/${selectedRowData.camera_id}`;
        axios.post(pageUpdates).catch((error) => {
            console.error('Error calling API:', error);
        });

        try {
            const response = await fetch(`${config.apiBaseUrl}DeleteCamDeta`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ CameraId: selectedRowData.camera_id }),
            });

            const result = await response.json();

            if (response.ok && result.length > 0) {
                fetchCameraDatas();
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }
    };


    useEffect(() => {
        fetchCameraDatas();
    }, [fetchCameraDatas]);

    const itemsPerPage = 30;
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = cameras.slice(firstIndex, lastIndex);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const openPopupCameraModel = (sAction) => {
        setPopupAction(sAction);

        if (sAction === "Delete") {
            setPopupVisible(true);
        }
    };

    const closePopup = () => {
        setPopupAction(null);
        fetchCameraDatas();
    };

    useEffect(() => {
        if (currentItems.length > 0 && isInit === true) {
            setSelectedRowIndex(0);
            setSelectedRowData(currentItems[0]);
            setisInit(false);
        }
    }, [currentItems, isInit]);

    const RefreshCameraPAge = useCallback(() => {
        setSearchValue('');
        fetchCameraDatas();
    }, [fetchCameraDatas]);

    const handleButtonClick = (button) => {
        if (button === 'Ok, Sure') {
            delCameraData();
            setPopupVisible(false);

        } else if (button === 'No, Cancel') {
            console.log('Cancel clicked');
            setPopupVisible(false);
        }
    };

    return (
        <>
            <div className={menuCollapse && loading ? 'clockloader-fd loading-clock-coll-cm' : (loading ? 'clockloader-fd loading-clock-cm' : '')}>
                <ClockLoader color="#3b77c6" loading={loading} className='loading-clock-fd' size={72} />
            </div>
            <div className='camera-cm'>
                <div className='header-cm FullWidth d-flex'>
                    <div className='col-6 d-flex align-items-start filter-sec-cm'>
                        <img src={search_icon} alt="search_icon" className='search_icon' />
                        <input className="search-filter-cm" type="search here" placeholder="Search" aria-label="Search"
                            value={searchValue}
                            onChange={handleSearchChange} />
                        <p className='total-count-p-cm text-doted'> No of online devices : <span style={{ color: "#29c770" }}>
                            {activeCount === undefined || activeCount === null ? 0 : activeCount}</span> /
                            {totalCount === undefined || totalCount === null ? 0 : totalCount}
                        </p>
                    </div>
                    <div className="d-flex col-6 align-items-start button-cm ">
                        <button type="submit" title='Live camera' onClick={() => openPopupCameraModel("Live")} className="btn btn-primary d-flex live-cam-cm">
                            <img src={live} alt='live' className='button-st-cm' />
                            <span className='cam-text-cm text-doted'>Live</span>
                        </button>
                        <div className='d-flex'>
                            <button type="submit" title='Add camera' onClick={() => openPopupCameraModel("Add")} className="btn btn-secondary d-flex add-cam-cm">
                                <img src={addcam} alt='addcam' className='button-st-cm' />
                            </button>
                            <button type="submit" title='Edit camera' onClick={() => openPopupCameraModel("Edit")} className="btn btn-secondary d-flex add-cam-cm">
                                <img src={editcam} alt='editcam' className='button-st-cm' />
                            </button>
                            <button type="submit" title='Delete camera' onClick={() => openPopupCameraModel("Delete")} className="btn btn-secondary d-flex add-cam-cm delete-cam-cm">
                                <img src={cam_delete} alt='cam_delete' className='button-st-cm' />
                            </button>
                            {/* Conditionally rendering the popup based on the state */}
                            {popupAction === "Add" && (
                                <AddEditPagePopup closePopup={closePopup} type="Add" RowData="" />
                            )}

                            {popupAction === "Edit" && (
                                <AddEditPagePopup closePopup={closePopup} type="Edit" RowData={selectedRowData} />
                            )}
                            {popupAction === "Live" && (
                                <LiveCamPage closePopup={closePopup} camData={cameras} />
                            )}
                            {popupAction === "Delete" && isPopupVisible && (
                                <Popup buttons={['Ok, Sure', 'No, Cancel']} onButtonClick={handleButtonClick}>
                                    <h5 className='dlt-cam-cm'>Delect camera</h5>
                                    <p className='dlt-txt-cm'>Are you sure to delete the '{selectedRowData.camera_name}' ?</p>
                                </Popup>
                            )}
                        </div>
                    </div>
                </div>
                <div className='body-cm FullWidth'>
                    <div className='FullWidth FullHeight'>
                        <div className='FullWidth table-cm FullHeight'>
                            <div className='FullWidth table-body'>
                                <div className='d-flex row-header-cm'>
                                    <div className='table-colhd-cm table-snum-cm text-doted set-minwidth-sno'>S.No</div>
                                    <div title='Camera name' className='table-colhd-cm table-col-cm text-doted set-minwidth-col'>Camera name</div>
                                    <div title='Camera brand' className='table-colhd-cm table-col-cm text-doted set-minwidth-col'>Camera brand</div>
                                    <div title='IP Address' className='table-colhd-cm table-col-cm text-doted set-minwidth-col'>Ping Address</div>
                                    <div title='Status' className='table-colhd-cm table-col-cm text-doted set-minwidth-col'>Status</div>
                                    <div title='Created date' className='table-colhd-cm table-col-cm border-right-cm text-doted set-minwidth-col'>Created date</div>
                                </div>
                                <div className='row-container-cm'>
                                    {totalCount === 0 ? (
                                        <div className='nodata-list-cm'>
                                            <img src={nodatalist} alt='nodatalist' className='nodatalist-cm' />
                                            <div className='no-item-cm'>
                                                No data found ...!
                                            </div>
                                        </div>
                                    ) : (
                                        currentItems.map((item, index) => (
                                            <div key={item.id || index}
                                                className={`d-flex row-body-cm ${index === selectedRowIndex ? 'selected-row' : ''}`}
                                                onClick={() => handleRowClick(index, item)}>
                                                <div title={item.id} className='table-snum-cm text-doted text-al-center set-minwidth-sno'>
                                                    {firstIndex + index + 1}
                                                </div>
                                                <div title={item.camera_name} className='table-col-cm text-doted set-minwidth-col'>
                                                    {item.camera_name}
                                                </div>
                                                <div title={item.brand} className='table-col-cm text-doted set-minwidth-col'>
                                                    {item.brand}
                                                </div>
                                                <div title={item.ip_address} className='table-col-cm text-doted set-minwidth-col'>
                                                    {item.ip_address}
                                                </div>
                                                <div title={item.status} className='table-col-cm text-doted d-flex set-minwidth-col'>

                                                    <div>
                                                        {item.status === "Active" ?
                                                            <img src={active_sign} className='sign-className' alt='active_sign' />
                                                            : <img src={deactive_sign} className='sign-className' alt='active_sign' />
                                                        }
                                                    </div>
                                                    <div>
                                                        {item.status}
                                                    </div>
                                                </div>
                                                <div className='table-col-cm text-doted border-right-cm text-al-center'>
                                                    {format(new Date(item.created_at), 'MM-dd-yyyy')}
                                                </div>
                                            </div>
                                        )))}
                                </div>
                            </div>
                            <div className='d-flex row-footer-cm justify-content-between'>
                                <div>
                                    <button type="submit" title='Refresh page' onClick={RefreshCameraPAge} className="btn btn-secondary d-flex add-cam-cm ">
                                        <img src={refresh} alt='cam_delete' className='button-st-cm refresh-btn-cm' />
                                        <span className='refresh-txt-cm'>Refresh</span>
                                    </button>
                                </div>
                                <div>
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={Math.ceil(cameras.length / itemsPerPage)}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            shape="rounded"
                                        />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Camera;
