import './App.css';
import React from 'react';
import OnBoarding from './pages/onboard.js';
import Admin from './pages/admin.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './auth/PrivateRoute.js';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div className='page'>
      <Router>
        <Routes>
          <Route path="/" element={<OnBoarding />} />
          <Route path="/admin" element={
            <PrivateRoute>
               <Admin />
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
