import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './filter-modal.css';
import config from '../config.js';
import { ToastContainer, Zoom, toast } from 'react-toastify';

const FilterModal = ({ closeFilterPopup, filterParams, rowData, pageName }) => {
  const [cameraOptions, setCameraOptions] = useState([]);
  const [temperOptions, setTemperOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [statusOptions] = useState(["Present", "Absent", "Late comer"]);

  const [selectedCamera, setSelectedCamera] = useState(filterParams.camera || '');
  const [selectedCameraTemp, setSelectedCameraTemp] = useState(filterParams.camera || '');
  const [selectedDepartment, setSelectedDepartment] = useState(filterParams.department || '');
  const [selectedStatus, setSelectedStatus] = useState(filterParams.status || '');
  const [startDate, setStartDate] = useState(filterParams.startDate || '');
  const [endDate, setEndDate] = useState(filterParams.endDate || '');
  const [filterModParams, setFilterModeParams] = useState({});

  const handleClear = () => {
    setSelectedCamera('');
    setSelectedCameraTemp('');
    setSelectedDepartment('');
    setSelectedStatus('');
    setStartDate('');
    setEndDate('');
  };

  useEffect(() => {
    const getFilterDetails = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}GetAllFilters`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        const result = await response.json();

        if (response.ok) {
          setCameraOptions(result.cameras || []);
          setDepartmentOptions(result.departments || []);
          setTemperOptions(result.temperType || []);
        } else {
          console.error('Error: ' + result.message);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    getFilterDetails();
  }, []);

  const formatDateTime = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleApply = () => {
    if ((startDate && !endDate) || (!startDate && endDate)) {
      toast.error("Please select both Start Date and End Date, or leave both empty.");
      return;
    }

    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      toast.error("End Date cannot be earlier than Start Date.");
      return;
    }

    const formattedStartDate = startDate ? formatDateTime(startDate) : null;
    const formattedEndDate = endDate ? formatDateTime(endDate) : null;

    const fltrModel = {
      camera: selectedCamera,
      tempertype: selectedCameraTemp,
      department: selectedDepartment,
      status: selectedStatus,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }

    const filteredCount = getAppliedFilterCount(fltrModel);
    setFilterModeParams(fltrModel);

    closeFilterPopup(fltrModel, filteredCount);

  };

  const getAppliedFilterCount = (filters) => {
    let count = Object.values(filters).filter(value => value !== null && value !== undefined && value !== '').length;

    if(filters.startDate && filters.endDate) {
      count = count - 1;
    }

    return count;
  };

  return (
    <div className="popup">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
      <div className="popup-content popup-content-fm filter-modal-content">
        <div className="filter-header">
          <h3>Filter by</h3>
          <button className="close-button" onClick={() => closeFilterPopup(filterModParams)}>×</button>
        </div>

        <div className="filter-body">
          {/* Camera Dropdown */}
          {(pageName === "Unknown person" || pageName === "Cemara tempering" || pageName === "Poeple in/out" || pageName === "ANPR" || pageName === "PPE" || pageName === "Vehicle in/out" || pageName === "Tripwire"  || pageName === "Trespassing" ||  pageName === "Gender detection" || pageName === "Crowd detection" || pageName === "Facial attendance") && (
            <div className="filter-row">
              <label>Camera</label>
              <div className='FullWidth'>
                <button
                  className="btn btn-primary dropdown-toggle dropdown-button"
                  type="button"
                  id="cameraDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedCamera || "Select Camera"}
                </button>
                <ul className="dropdown-menu drop-menu-fm" aria-labelledby="cameraDropdown">
                  {cameraOptions.map((camera, index) => (
                    <li
                      key={index}
                      className="dropdown-item"
                      onClick={() => setSelectedCamera(camera.camera_name)}
                    >
                      {camera.camera_name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {/* Camera temper type Dropdown */}
          {pageName === "Cemara tempering" && (
            <div className="filter-row">
              <label>Camera</label>
              <div className='FullWidth'>
                <button
                  className="btn btn-primary dropdown-toggle dropdown-button"
                  type="button"
                  id="cameraTemperDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedCameraTemp || "Select type"}
                </button>
                <ul className="dropdown-menu drop-menu-fm" aria-labelledby="cameraTemperDropdown">
                  {temperOptions.map((item, index) => (
                    <li
                      key={index}
                      className="dropdown-item"
                      onClick={() => setSelectedCameraTemp(item.tampering_type)}
                    >
                      {item.tampering_type}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {/* Department Dropdown */}
          {pageName === "Facial attendance" && (
            <div className="filter-row">
              <label>Department</label>
              <div className='FullWidth'>
                <button
                  className="btn btn-primary dropdown-toggle dropdown-button"
                  type="button"
                  id="departmentDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedDepartment || "Select Department"}
                </button>
                <ul className="dropdown-menu drop-menu-fm" aria-labelledby="departmentDropdown">
                  {departmentOptions.map((dept, index) => (
                    <li
                      key={index}
                      className="dropdown-item"
                      onClick={() => setSelectedDepartment(dept.department)}
                    >
                      {dept.department}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {/* Status Dropdown */}
          {pageName === "Facial attendance" && (
            < div className="filter-row">
              <label>Status</label>
              <div className='FullWidth'>
                <button
                  className="btn btn-primary dropdown-toggle dropdown-button"
                  type="button"
                  id="statusDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedStatus || "Select Status"}
                </button>
                <ul className="dropdown-menu drop-menu-fm" aria-labelledby="statusDropdown">
                  {statusOptions.map((status, index) => (
                    <li
                      key={index}
                      className="dropdown-item"
                      onClick={() => setSelectedStatus(status)}
                    >
                      {status}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {/* Date Range */}
          {(pageName === "Unknown person"  || pageName === "Cemara tempering" || pageName === "Poeple in/out" || pageName === "ANPR" || pageName === "PPE" || pageName === "Vehicle in/out" || pageName === "Tripwire" || pageName === "Trespassing" ||  pageName === "Gender detection" || pageName === "Crowd detection" || pageName === "Facial attendance") && (
            <div className="filter-row">
              <label>Date</label>
              <div className="date-range">
                <input
                  type="date"
                  value={startDate.split(' ')[0]}
                  onChange={(e) => setStartDate(e.target.value)}
                  className='start-date-fm'
                />
                <span> - </span>
                <input
                  type="date"
                  value={endDate.split(' ')[0]}
                  onChange={(e) => setEndDate(e.target.value)}
                  className='end-date-fm'
                />
              </div>
            </div>
          )}
        </div>
        <div className="filter-footer">
          <button className="clear-button" onClick={handleClear}>
            Clear
          </button>
          <button className="apply-button" onClick={handleApply}>
            Apply
          </button>
        </div>
      </div>
    </div >
  );
};

export default FilterModal;
