import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PeopleInOutChart = () => {
  const [state] = React.useState({
    series: [
      {
        name: 'In',
        data: [30, 25, 15, 5]
      },
      {
        name: 'Out',
        data: [10, 20, 30, 45] 
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '25%',
          borderRadius: 5, 
          borderRadiusApplication: 'top' // Apply the radius only to the top corners
        }
      },
      colors: ['#d92786', '#1d69b5'], 
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['9am-12pm', '12pm-3pm', '3pm-6pm', '6pm-9am'],
      },
      yaxis: {
        title: {
          text: 'People Count'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' people';
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        markers: {
          width: 10,
          height: 10
        }
      }
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="bar" height={250} />
      </div>
    </div>
  );
};

// Default export of the component
export default PeopleInOutChart;
