import React, { useState, useEffect, useCallback, useRef } from 'react';
import '../assets/styles/members.css';
import search_icon from '../assets/images/search_icon.svg';
import adduser from '../assets/images/adduser.svg';
import config from '../config';
import { toast } from 'react-toastify';
import call_icon from '../assets/images/call_icon.svg';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import user_edit from '../assets/images/user_edit.svg';
import user_delete from '../assets/images/user_delete.svg';
import no_users from '../assets/images/no_users.svg';
import AddEditMembers from './addeditmembers.js';
import ifnotuser from '../assets/images/ifnotuser.svg';
import Popup from '../vendor/popup.js';
import axios from 'axios';
import { ClockLoader } from 'react-spinners';

function Members({pageName, menuCollapse}) {
    const [searchValue, setSearchValue] = useState('');
    const [members, setMembers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [noItemFound, SetNoItemFound] = useState(false);
    const itemsPerPage = 12;
    const [popupAction, setPopupAction] = useState(null);
    const [selectedItems, setSelectedItems] = useState(null);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const dropdownRefs = useRef([]);
    const [loading, setLoading] = useState(true);

    const toggleDOTDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };

    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;

    const currentItems = members.slice(firstIndex, lastIndex);

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const fetchMembersData = useCallback(async () => {
        try {
            setLoading(true);
            const trimmedSearchValue = searchValue.trim();

            const response = await fetch(`${config.apiBaseUrl}GetMembersDetas`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ SearchedValue: trimmedSearchValue }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Result from API:', result);

                if (Array.isArray(result) && result.length > 0) {
                    SetNoItemFound(false);
                    setMembers(result);
                } else {
                    SetNoItemFound(true);
                }
            } else {
                const errorMessage = await response.text();
                toast.error(errorMessage);
                console.error('Error response:', response.status, errorMessage);
            }

        } catch (error) {
            console.error('Fetch error:', error.message || error);
        }

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [searchValue]);

    useEffect(() => {
        fetchMembersData();
    }, [fetchMembersData]);

    const closePopup = () => {
        setPopupAction(null);
        fetchMembersData();
    };

    const openPopupCameraModel = (sAction, objData = null) => {
        setPopupAction(sAction);

        if (objData !== null) {
            setSelectedItems(objData);
        }

        if (sAction === "Delete") {
            setPopupVisible(true);
        }
    };

    const delMembersData = async () => {
        const pageUpdates = `${config.apiBaseUrlPy}pageupdates/members/delete/${selectedItems.members_id}`;
        axios.post(pageUpdates).catch((error) => {
            console.error('Error calling API:', error);
        });

        try {
            const response = await fetch(`${config.apiBaseUrl}DeleteMember`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ MemberId: selectedItems.members_id }),
            });

            const result = await response.json();

            if (response.ok && result.length > 0) {
                fetchMembersData();
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    const handleButtonClick = (button) => {
        if (button === 'Ok, Sure') {
            delMembersData();
            setPopupVisible(false);

        } else if (button === 'No, Cancel') {
            console.log('Cancel clicked');
            setPopupVisible(false);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRefs.current[openDropdownIndex] && !dropdownRefs.current[openDropdownIndex].contains(event.target)) {
                setOpenDropdownIndex(null);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdownIndex]);

    return (
        <>
            <div className={menuCollapse && loading ? 'clockloader-fd loading-clock-coll-cm' : (loading ? 'clockloader-fd loading-clock-cm' : '')}>
                <ClockLoader color="#3b77c6" loading={loading} className='loading-clock-fd' size={72} />
            </div>
            <div className='FullWidth FullHeight main-members'>
                <div className='header-mm d-flex'>
                    <div className='col-sm-8 col-md-4 search-div-mm'>
                        <img src={search_icon} alt="search_icon" className='search_icon' />
                        <input className="search-filter-mm" type="search here" placeholder="Search" aria-label="Search"
                            value={searchValue} onChange={handleSearchChange} />
                    </div>
                    <button type="submit" title='Edit user' onClick={() => openPopupCameraModel("Add")} className="btn btn-secondary d-flex add-user-mm ">
                        <img src={adduser} alt='adduser' className='button-st-mm' /> <span className='addtext-mm'>Add</span>
                    </button>
                    {popupAction === "Add" && (
                        <AddEditMembers closePopup={closePopup} type="Add" RowData="" />
                    )}
                    {popupAction === "Edit" && (
                        <AddEditMembers closePopup={closePopup} type="Edit" RowData={selectedItems} />
                    )}
                    {popupAction === "Delete" && isPopupVisible && (
                        <Popup buttons={['Ok, Sure', 'No, Cancel']} onButtonClick={handleButtonClick}>
                            <h5 className='dlt-cam-cm'>Delect member</h5>
                            <p className='dlt-txt-cm'>Are you sure to delete '{selectedItems.members_name}'?</p>
                        </Popup>
                    )}
                </div>
                <div className='body-mm'>
                    {noItemFound === false ? (
                        <div className='row main-container-mm'>
                            {currentItems.map((item, index) => (
                                <div key={item.id || index} className='col-lg-3 col-md- 4 col-sm-6 col-12 sub-main-container-mm'>
                                    <div className='image-div-con-mm' ref={(el) => (dropdownRefs.current[index] = el)}>
                                        <div className="dropdown">
                                            <button className="three-dots" onClick={() => toggleDOTDropdown(index)}>⋮</button>
                                            <div className={`dropdown-content ${openDropdownIndex === index ? "show" : ""}`}>
                                                <button className='edit-icon-mm' onClick={() => openPopupCameraModel("Edit", item)}>
                                                    <img src={user_edit} alt="edit icon" />
                                                </button>
                                                <button className='dlt-icon-mm' onClick={() => openPopupCameraModel("Delete", item)}>
                                                    <img src={user_delete} alt="delete icon" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className='imgae-div-mm'>
                                            {item.img_1 === "" || item.img_1 === null ? (
                                                <img src={ifnotuser} className='profile-mm' alt="profile" />
                                            ) : (
                                                <img src={item.img_1} className='profile-mm' alt="profile" />
                                            )}
                                        </div>
                                        <div title={item.members_name} className='members-data-mm member-name-mm text-doted'> {item.members_name} </div>
                                        <div title={item.members_id} className='members-data-mm member-id-mm'> {item.members_id} </div>
                                        <div title={item.department} className='members-data-mm member-dept-mm text-doted'> {item.department} </div>
                                        <div title={item.mobile} className='members-data-mm text-doted'>
                                            <img src={call_icon} alt="call icon" />
                                            <span className='mobile-st-mm text-doted'>{item.mobile}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='no-users-mm'>
                            <img src={no_users} className='no-users-img-mm' alt='no_users' />
                            <p className='no-users-txt-mm'>No users found ...!</p>
                        </div>
                    )}
                </div>
                <div className='footer-mm'>
                    <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(members.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            shape="rounded"
                        />
                    </Stack>
                </div>
            </div>
        </>
    );
}

export default Members;
