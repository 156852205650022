import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PrivateRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem("authToken");

    console.log("Is authenticated:", isAuthenticated); // Debugging line

    if (!isAuthenticated) {
        toast.warning("You must be logged in to access this page.");
        return <Navigate to="/" />;
    }

    return children;
};

export default PrivateRoute;
