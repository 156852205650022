import React , { useState , useEffect} from 'react';
import VehiclInOut from './vehicle-inout.js';
import ANPR from './anpr.js';

function VehicleDetect({ onType , menuCollapse}) {
    const [pageName, setPageName] = useState('');

    useEffect(() => {
        setPageName(onType);
    }, [onType]); 

    return (
        <div className='FullWidth FullHeight'>
            {pageName === 'Vehicle in/out' && <VehiclInOut pageName={pageName} menuCollapse={menuCollapse} />}
            {pageName === 'ANPR' && <ANPR pageName={pageName} menuCollapse={menuCollapse} />}
        </div>
    );
}

export default VehicleDetect;