import React, { useRef, useEffect } from "react";

const CanvasWithPolygon = ({ points, width, height }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        context.clearRect(0, 0, canvas.width, canvas.height);

        if (points.length >= 2) {
            context.beginPath();
            context.strokeStyle = "blue";
            points.forEach(({ x, y }, index) => {
                if (index === 0) {
                    context.moveTo(x, y);
                } else {
                    context.lineTo(x, y);
                }
            });
            context.closePath();
            context.stroke();
        }
    }, [points]);

    return (
        <div>
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
                className="canvas-select-rs"
            />
        </div>
    );
};

export default CanvasWithPolygon;
