import React, { useState, useEffect, useCallback } from 'react';
import '../assets/styles/dashboard.css';
import db_bgcamera from '../assets/images/db_bgcamera.svg';
import total_cam from '../assets/images/total_cam.svg';
import active_came from '../assets/images/active_came.svg';
import inact_cam from '../assets/images/inact_cam.svg';
import db_bgattendance from '../assets/images/db_bgattendance.svg';
import db_bgunknown from '../assets/images/db_bgunknown.svg';
import db_bganpr from '../assets/images/db_bganpr.svg';
//import CrowdDetectChart from '../vendor/dashboard/crowddetect-db.js';
import AttendanceChart from '../vendor/dashboard/attendance-db.js';
import db_bgcrowd from '../assets/images/db_bgcrowd.svg';
import PeopleInOutChart from '../vendor/dashboard/peopleinout-db.js';
import VehicleInOutChart from '../vendor/dashboard/vehicleinout-db.js';
import GenderClassificationChart from '../vendor/dashboard/genderclassify-db.js';
import db_bgcamtemp from '../assets/images/db_bgcamtemp.svg';
import db_bgtrespass from '../assets/images/db_bgtrespass.svg';
import db_ppe from '../assets/images/db_ppe.svg';
import config from '../config.js';
import sad_status from '../assets/images/sad_status.svg';
import smile_status from '../assets/images/smile_status.svg';

// Dashboard component
function Dashboard() {
    const [number1, setNumber1] = useState(0);
    const [number2, setNumber2] = useState(0);
    const [number3, setNumber3] = useState(0);
    const [unknownCount, setUnknownCount] = useState(0);
    const [activeCount, setActiveCount] = useState(0);
    const [inactiveCount, setInactiveCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [unknownCountDb, setUnknownCountDb] = useState(0);
    const [numberPlate, setNumberPlate] = useState('');
    const [crowdStatus, setCrowdStatus] = useState('No result');
    const [totalMembers, setTotalMembers] = useState(0);
    const [presentMembers, setPresentMembers] = useState(0);
    const [attendanceCount, setAttendanceCount] = useState('');
    const [genderCount, setGenderCount] = useState('');
    const [totalGenderMembers, setTotalGenderMembers] = useState(0);
    const [obstructCount, setObstructCount] = useState(0);
    const [movedCount, setMovedCount] = useState(0);
    const [lossCount, setLossCount] = useState(0);
    const [ppeCount, setPPECount] = useState(0);
    const [humanCount, setHumanCount] = useState(0);
    const [vehicleCount, setVehicleCount] = useState(0);
    const [tressCount, setTressCount] = useState(0);

    const fetchUsecaseDetails = useCallback(async () => {
        try {
            const response = await fetch(`${config.apiBaseUrl}GetUsecaseDetails`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            const result = await response.json();
            if (result !== null && result !== undefined && result.Camera.length > 0) {
                result.Camera.forEach((item) => {
                    if (item.status === 'Active') {
                        setActiveCount(item.status_count);
                    }
                    if (item.status === 'Inactive') {
                        setInactiveCount(item.status_count);
                    }
                });

                if (result.UnknownTrack.length > 0 && result.UnknownTrack[0].UnknownCount !== null && result.UnknownTrack[0].UnknownCount !== undefined) {
                    setUnknownCountDb(result.UnknownTrack[0].UnknownCount);
                }

                if (result.anpr.length > 0 && result.anpr[0].number !== null && result.anpr[0].number !== undefined) {
                    setNumberPlate(result.anpr)
                }

                if (result.crowdDetect.length > 0 && result.crowdDetect[0] !== null && result.crowdDetect[0] !== undefined) {
                    if (result.crowdDetect[0].CrowdStatus !== '') {
                        setCrowdStatus(result.crowdDetect[0].CrowdStatus);
                    }
                }

                if (result.AttendanceCount.length > 0 && result.AttendanceCount[0] !== null && result.AttendanceCount[0] !== undefined) {
                    setTotalMembers(result.AttendanceCount[0].PresentCount);
                    setPresentMembers(result.AttendanceCount[0].TotalCount);
                    setAttendanceCount(result.AttendanceCount[0]);
                }

                if (result.GenderCount.length > 0 && result.GenderCount[0] !== null && result.GenderCount[0] !== undefined) {
                    setGenderCount(result.GenderCount[0]);
                    setTotalGenderMembers(result.GenderCount[0].TotalMembers);
                }

                if (result.PPECount.length > 0 && result.PPECount[0] !== null && result.PPECount[0] !== undefined) {
                    setPPECount(result.PPECount[0].PPECount);
                }

                if (result.CamTemperCount.length > 0 && result.CamTemperCount[0] !== null && result.CamTemperCount[0] !== undefined) {
                    setObstructCount(result.CamTemperCount[0].ObstructCount);
                    setMovedCount(result.CamTemperCount[0].MovedCount);
                    setLossCount(result.CamTemperCount[0].LossCount);
                }

                if(result.TripCount.length > 0 && result.TripCount[0] !== null && result.TripCount[0] !== undefined) {
                    setHumanCount(result.TripCount[0].HumanCount);
                    setVehicleCount(result.TripCount[0].VehicleCount);
                }

                if(result.TressCount.length > 0 && result.TressCount[0] !== null && result.TressCount[0] !== undefined) {
                    setTressCount(result.TressCount[0].TressCount);
                }

            } else {
                if (!response.ok) {
                    console.error('Error: ' + result.message);
                }
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }
    }, []);

    useEffect(() => {
        fetchUsecaseDetails();
    }, [fetchUsecaseDetails]);

    useEffect(() => {
        setTotalCount(activeCount + inactiveCount);
    }, [activeCount, inactiveCount]);

    useEffect(() => {
        const animateNumber = (setNumber, target, duration) => {
            let start = 0;
            const step = () => {
                const increment = target / (duration / 50); 
                start += increment;

                if (start >= target) {
                    setNumber(target);
                    return;
                }

                setNumber(Math.floor(start));
                requestAnimationFrame(step);
            };

            requestAnimationFrame(step);
        };

        animateNumber(setNumber1, (activeCount + inactiveCount), 2000);
        animateNumber(setNumber2, activeCount, 2000);
        animateNumber(setNumber3, inactiveCount, 2000);
        animateNumber(setUnknownCount, unknownCountDb, 2000);
        animateNumber(setObstructCount, obstructCount, 2000);
        animateNumber(setMovedCount, movedCount, 2000);
        animateNumber(setLossCount, lossCount, 2000);
    }, [totalCount, activeCount, inactiveCount, unknownCountDb, obstructCount, movedCount, lossCount]);


    const getCrowdColor = (status) => {
        if (status !== "High crowd") {
            return {
                color: '#f6c535',
                fontWeight: '600'
            };
        }
        return {
            color: '#db2121',
            fontWeight: '600'
        };
    };


    return (
        <div className="FullWidth FullHeight main-dashboard-db">
            <div className="container-main">
                <div className="row g-3">

                    {/* Total Cameras */}
                    <div className="col-lg-3 col-md-6 col-sm-6 card-small-db">
                        <div className="card text-center p-3 FullHeight camera-count-db">
                            <img src={db_bgcamera} alt="Background Camera" className='db-bgcamera' />
                            <div className='d-flex flex-wrap'>
                                <div className='col-4 cam-container-db'>
                                    <img src={total_cam} alt='total_cam' className='com-padd-img total-cam-db ' />
                                    <div className='cam-count-text'>
                                        <p className='margin-rem-db text-doted'>Total </p>
                                        <p className='margin-rem-db text-doted'>Camera </p>
                                    </div>
                                    <p className='margin-rem-db camcount-data-db text-doted'>{number1}</p>
                                </div>
                                <div className='col-4 cam-container-db'>
                                    <img src={active_came} alt='active_came' className='com-padd-img active-cam-db ' />
                                    <div className='cam-count-text'>
                                        <p className='margin-rem-db text-doted'>Active </p>
                                        <p className='margin-rem-db text-doted'>Camera </p>
                                    </div>
                                    <p className='margin-rem-db camcount-data-db text-doted'>{number2}</p>
                                </div>
                                <div className='col-4 cam-container-db'>
                                    <img src={inact_cam} alt='inact_cam' className='com-padd-img inact-cam-db ' />
                                    <div className='cam-count-text'>
                                        <p className='margin-rem-db text-doted'>Inactive </p>
                                        <p className='margin-rem-db text-doted'>Camera </p>
                                    </div>
                                    <p className='margin-rem-db camcount-data-db text-doted'>{number3}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Unknown person detection */}
                    <div className="col-lg-3 col-md-6 col-sm-6 card-small-db">
                        <div className="card p-3 FullHeight box-container">
                            <img src={db_bgunknown} alt='db_bgunknown' className='db-bgunknown' />
                            <h5 className="text-doted card-header-db mb-4 text-center">Unknown person</h5>
                            <p className='detected-text-db'>Detected:</p>
                            <h5 className='unknown-dedect-db'>
                                {unknownCount}
                            </h5>
                        </div>
                    </div>

                    {/* ANPR */}
                    <div className="col-lg-3 col-md-6 col-sm-6 card-small-db">
                        <div className="card p-3 FullHeight box-container">
                            <img src={db_bganpr} alt='db_bganpr' className='db-bgunknown' />
                            <h5 className="text-doted card-header-db text-center">ANPR</h5>
                            <p className='detected-text-db text-style-np'>Number plate lists:</p>
                            <div className='number-pldiv-db'>
                                {numberPlate ? (
                                    Array.isArray(numberPlate) && numberPlate.map((item, index) => (
                                        <h5 key={index} className="number-plate-db">
                                            {item.number}
                                        </h5>
                                    ))) : (
                                    <h5 className="number-plate-db p-2 mt-3">
                                        XX 00 X 0000
                                    </h5>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Crowd Detection */}
                    <div className="col-lg-3 col-md-6 col-sm-6 card-small-db">
                        <div className="card text-center p-3 box-container FullHeight">
                            <img src={db_bgcrowd} alt='db_bgcrowd' className='db-bgcrowd' />
                            <h5 className="text-doted card-header-db">Crowd Detection</h5>
                            <div className='d-flex justify-content-center align-items-center flex-column flex-wrap mt-2 title-container'>
                                <p className='mb-0 title-db text-doted'>Density Level:</p>
                                <div style={getCrowdColor(crowdStatus)} className='crowd-status-db'>
                                    {crowdStatus}
                                    {crowdStatus === "High crowd" ? (<img src={sad_status} alt='sad_status' className='sad-status-db  mt-2' />)
                                        : (<img src={smile_status} alt='smile_status' className='sad-status-db  mt-2' />)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* People In/Out */}
                    <div className="col-lg-6 col-md-12 col-sm-12 card-big-db">
                        <div className="card p-3 FullHeight box-container">
                            <h5 className="text-doted card-header-db">People In/Out</h5>
                            <div className="text-center"><PeopleInOutChart /></div>
                        </div>
                    </div>

                    {/* Attendance Report */}
                    <div className="col-lg-6 col-md-12 col-sm-12 card-big-db">
                        <div className="card p-3 FullHeight box-container attendance-db">
                            <h5 className="text-doted card-header-db">Attendance Report</h5>
                            <div className="text-center attendance-chart-db">
                                {attendanceCount === "" && <div className='no-result-at-div'><p className='no-result-at' >No result ...!</p></div>}
                                <AttendanceChart rowData={attendanceCount} />
                                <img src={db_bgattendance} alt='db_bgattendance' className='db-bgattendance' />
                            </div>
                            <div className='pt-5'>
                                <p className='common-text-db'>Total members - <span className='com-membercount-db'>{totalMembers}</span></p>
                                <p className='common-text-db'>Present - <span className='com-membercount-db'>{presentMembers}</span></p>
                            </div>
                        </div>
                    </div>

                    {/* Placeholder Charts */}
                    <div className="col-lg-6 col-md-12 col-sm-12 card-big-db">
                        <div className="card p-3 FullHeight box-container">
                            <h5 className="text-doted card-header-db">Vehicle In/Out</h5>
                            <div className="text-center">
                               {/*  {attendanceCount === "" && <div className='no-result-at-div'><p className='no-result-at' >No result ...!</p></div>} */}
                                <VehicleInOutChart />
                            </div>
                        </div>
                    </div>


                    {/* Gender Classification */}
                    <div className="col-lg-6 col-md-12 col-sm-12 card-big-db">
                        <div className="card p-3 FullHeight box-container">
                            <h5 className="text-doted card-header-db">Person - Gender Classification</h5>
                            <div className="d-flex justify-content-between align-items-center">
                                {(genderCount.length === 0 || genderCount === '') && <div className='no-result-at-div'><p className='no-result-at' >No result ...!</p></div>}
                                <GenderClassificationChart rowData={genderCount} />
                            </div>
                            <div className='pt-5'>
                                <p className='common-text-db'>Total members - <span className='com-membercount-db'>{totalGenderMembers}</span></p>
                            </div>
                        </div>
                    </div>

                    {/* PPE */}
                    <div className="col-lg-3 col-md-6 col-sm-6 card-small-db">
                        <div className="card p-3 FullHeight box-container">
                            <img src={db_ppe} alt='db_ppe' className='db-bgcamtemp' />
                            <h5 className="text-doted card-header-db">PPE</h5>
                            <p className='detected-text-db ppe-detected-db'>Detected:</p>
                            <h3 className='text-center unknown-dedect-db'>{ppeCount}</h3>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 card-small-db">
                        <div className="card p-3 FullHeight box-container">
                            <img src={db_bgcamtemp} alt='db_bgcamtemp' className='db-bgcamtemp' />
                            <h5 className="text-doted card-header-db">Camera Tampering</h5>
                            <p>Active alerts - </p>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <div className='d-flex align-items-center'>
                                    <div className='cam-temp obstructed-db'></div> &nbsp;&nbsp;
                                    <div>Obstructed</div>
                                </div>
                                <div className='value-ct'>{obstructCount}</div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <div className='d-flex align-items-center'>
                                    <div className='cam-temp moved-db'></div>&nbsp;&nbsp;
                                    <div>Moved</div>
                                </div>
                                <div className='value-ct'>{movedCount}</div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <div className='d-flex align-items-center'>
                                    <div className='cam-temp signallos-db'></div>&nbsp;&nbsp;
                                    <div> Signal loss</div>
                                </div>
                                <div className='value-ct'>{lossCount}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 card-small-db">
                        <div className="card p-3 FullHeight box-container">
                            <img src={db_bgtrespass} alt='db_bgtrespass' className='db-bgcamtemp' />
                            <h5 className="text-doted card-header-db">Trip-wir</h5>
                            <p>Active alerts - </p>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <div className='d-flex align-items-center'>
                                    <div className='cam-trip'></div> &nbsp;&nbsp;
                                    <div>Human</div>
                                </div>
                                <div className='value-ct'>{humanCount}</div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <div className='d-flex align-items-center'>
                                    <div className='cam-trip'></div>&nbsp;&nbsp;
                                    <div>Vehicle</div>
                                </div>
                                <div className='value-ct'>{vehicleCount}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 card-small-db">
                        <div className="card p-3 FullHeight box-container">
                            <img src={db_bgtrespass} alt='db_bgtrespass' className='db-bgcamtemp' />
                            <h5 className="text-doted card-header-db">Trespass</h5>
                            <p>Active alerts - </p>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <div className='d-flex align-items-center'>
                                    <div className='cam-trespass'></div> &nbsp;&nbsp;
                                    <div>Restricted area
                                        breach</div>
                                </div>
                                <div className='value-ct'>{tressCount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Dashboard;
