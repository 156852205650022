import React, { useState, useEffect } from 'react';
import FacialDetect from './facial-detection.js';
import UnknownDetect from './unknown-persondetect.js';

function FaceDetect({ onType , menuCollapse}) {
    const [pageName, setPageName] = useState('');

    useEffect(() => {
        setPageName(onType);
    }, [onType]); 

    return (
        <div className='FullWidth FullHeight'>
            {pageName === 'Facial attendance' && <FacialDetect pageName={pageName} menuCollapse={menuCollapse} />}
            {pageName === 'Unknown person' && <UnknownDetect pageName={pageName} menuCollapse={menuCollapse} />}
        </div>
    );
}

export default FaceDetect;