import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import static_glitch from '../assets/images/static_glitch.gif';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config.js';
import '../assets/styles/addeditcamera.css';
import addedit_cam from '../assets/images/addedit_cam.svg';
import { ScaleLoader } from 'react-spinners';
import axios from 'axios';

const AddEditPagePopup = ({ closePopup, type, RowData }) => {
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledConnect, setDisabledConnect] = useState(false);
  const [base64Image, setBase64Image] = useState('');
  const [imageS3Url, setImageS3Url] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    camera_name: RowData.camera_name || '',
    brand: RowData.brand || '',
    ip_address: RowData.ip_address || '',
    user_name: RowData.user_name || '',
    password: RowData.password || '',
    rtsp_url: RowData.rtsp_url || '',
  });

  const [initialData, setInitialData] = useState(formData);

  // Update initial data when RowData changes
  useEffect(() => {
    setInitialData({
      camera_name: RowData.camera_name || '',
      brand: RowData.brand || '',
      ip_address: RowData.ip_address || '',
      user_name: RowData.user_name || '',
      password: RowData.password || '',
      rtsp_url: RowData.rtsp_url || '',
    });
  }, [RowData]);

  // Function to find changes
  const findChanges = () => {
    const changes = Object.entries(formData)
      .filter(([key, newValue]) => newValue !== initialData[key])
      .map(([key, newValue]) => ({ key, newValue, camera_id: RowData.camera_id }));

    return changes;
  };

  const changedColumns = findChanges();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const ValidateCameraDatas = () => {
    if (type === "Add") {
      if (formData.camera_name === "") {
        toast.error("Please enter the camera name.");
        return;
      } if (formData.brand === "") {
        toast.error("Please enter the camera brand.");
        return;
      }
      if (formData.ip_address === "" && formData.user_name === "" && formData.password === "" && formData.rtsp_url === "") {
        toast.error("Please enter the RTSP URL or, alternatively, provide the IP address, Username, and Password.");
        return;
      }
      if ((formData.ip_address === "" && formData.user_name !== "" && formData.password !== "") ||
        (formData.ip_address !== "" && formData.user_name === "" && formData.password !== "") ||
        (formData.ip_address !== "" && formData.user_name !== "" && formData.password === "") ||
        (formData.ip_address === "" && formData.user_name === "" && formData.password !== "") ||
        (formData.ip_address !== "" && formData.user_name === "" && formData.password === "") ||
        (formData.ip_address === "" && formData.user_name !== "" && formData.password === "")) {

        if (formData.rtsp_url !== "") {
          handleSaveCamDatas();
          return;
        } else {
          toast.error("Please enter the camera credential.");
          return;
        }
      }
    };

    if (formData.rtsp_url !== "") {
      handleSaveCamDatas();
    }
    else if (formData.ip_address !== "" && formData.user_name !== "" && formData.password !== "") {
      handleSaveCamDatas();
    }
  };

  const ValidateConnection = async () => {
    let rtspurl = "";
    setLoading(true);

    if (formData.rtsp_url !== "") {
      rtspurl = formData.rtsp_url;
    }
    else if (formData.ip_address !== "" && formData.user_name !== "" && formData.password !== "") {
      rtspurl = "rtsp://" + formData.user_name + ":" + formData.password + "@" + formData.ip_address + ":554/cam/realmonitor?channel=1&subtype=0";
    }
    else {
      toast.error("Please enter the RTSP code or Camera credential");
    }

    if (rtspurl !== "") {
      try {
        const response = await fetch(`${config.apiBaseUrlPy}check_rtsp`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ rtsp_url: rtspurl }),
        });

        const result = await response.json();
        if (response.ok) {

          if (result.message !== "true") {
            setBase64Image('');
            setDisabledSave(true);
            setDisabledConnect(false);
            toast.error("Invalid credential !");
          }
          else if (result.message === "true") {
            let rtspCode = "";
            let streamUrl = "";

            if (formData.rtsp_url !== "") {
              rtspCode = encodeURIComponent(formData.rtsp_url);
              streamUrl = `${config.apiBaseUrlPy}add_feed/${rtspCode}`;
              setBase64Image(streamUrl);
              setDisabledSave(false);
              setDisabledConnect(true);
              setImageS3Url(result.url);


              const ipAddress = formData.rtsp_url.match(/@([\d.]+):/)[1];
              const username = formData.rtsp_url.match(/\/\/(.*?):/)[1];
              const password = formData.rtsp_url.match(/rtsp:\/\/.*:(.*?)@/)[1];

              setFormData((prev) => ({ ...prev, ip_address: ipAddress, user_name: username, password: password }));

            }
            else if (formData.ip_address !== "" && formData.user_name !== "" && formData.password !== "") {
              const makeRTSP = "rtsp://" + formData.user_name + ":" + formData.password + "@" + formData.ip_address + ":554/cam/realmonitor?channel=1&subtype=0";
              rtspCode = encodeURIComponent(makeRTSP);
              streamUrl = `${config.apiBaseUrlPy}add_feed/${rtspCode}`;
              setBase64Image(streamUrl);
              setDisabledSave(false);
              setDisabledConnect(true);

              setFormData((prev) => ({ ...prev, rtsp_url: makeRTSP }));
            }
            else {
              toast.error("Please enter the RTSP code or Camera credential");
            }
          }

        } else {
          toast.error('Error: ' + result.message);
        }
      } catch (error) {
        console.error(error);
        toast.error('An error occurred');
      }
    }
    else {
      toast.error("Please enter the RTSP code or Camera credential");
    }


    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleSaveCamDatas = async () => {
    let objDatas = {};
    let objAPI = "";

    if (type === "Edit") {
      if (changedColumns.length === 0) {
        toast.warning("No changes found.");
        return;
      } else {
        objDatas = changedColumns[0];
        objAPI = "UpdateCameraDatas";
      }
    }
    else if (type === "Add") {
      formData["url"] = imageS3Url;
      objDatas = formData;
      objAPI = "InsertCameraDatas";
    }

    try {
      const response = await fetch(`${config.apiBaseUrl}${objAPI}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(objDatas),
      });

      const result = await response.json();
      if (response.ok) {
        let message = "";
        if (type === "Add") {
          message = "Camera add successfully";
        }
        else if (type === "Edit") {
          message = "Camera update successfully";
        }
        toast.success(message);

        const pageUpdates =  `${config.apiBaseUrlPy}pageupdates/cameras/${type}/${result[0].camera_id}`;
        axios.post(pageUpdates).catch((error) => {
          console.error('Error calling API:', error);
        });

        setTimeout(() => {
          closePopup();

          setFormData({
            camera_name: '',
            brand: '',
            ip_address: '',
            user_name: '',
            password: '',
            rtsp_url: '',
          });
        }, 3000);

      } else {
        toast.error('Error: ' + result.message);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred');
    }
  };


  return (
    <Dialog open={true} onClose={closePopup} sx={{
      "& .MuiDialog-paper": {
        height: "76%",
        width: "70%",
        maxWidth: "70%",
      },}} >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
      <DialogTitle>
        <span className='addcam-title-cm'>{type === "Add" ? "Add Camera" : "Edit Camera"}</span>
        <img src={addedit_cam} className='addedit_cam-fd' alt="add_camera" />
      </DialogTitle>
      <DialogContent>
        <div className='body-addpopup-cm d-flex'>
          <div className='left-conpopup-cm '>
            <div className={loading ? 'clockloader-fd loading-clock-aec' : ''}>
              <ScaleLoader color="#3b77c6" loading={loading} className='loading-clock-fd' size={72} />
            </div>
            {base64Image !== "" ?
              <div className='d-flex align-items-center live-video-stream'>
                <img src={base64Image} className="live-connected" alt="Live Stream" style={{ width: '100%' }} />
              </div>
              :
              <img src={static_glitch} className='glitch-cm' alt="static_glitch" />
            }
          </div>
          <div className='right-conpopup-cm'>
            <div className='d-flex FullWidth'>
              <div className='container1-cm'>
                <label className='text-doted con1-label-cm'>Enter camera name <span style={{ color: "#f54040" }}>*</span></label>
                <input
                  type="text"
                  className="form-control ip-camera-cm"
                  id="camname"
                  name="camera_name"
                  placeholder='Enter camera name'
                  maxLength={100}
                  value={formData.camera_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='container1-cm'>
                <label className='text-doted con1-label-cm'>Enter camera brand <span style={{ color: "#f54040" }}>*</span></label>
                <input
                  type="text"
                  className="form-control ip-camera-cm"
                  id="camloc"
                  name="brand"
                  placeholder='Enter camera brand'
                  maxLength={100}
                  value={formData.brand}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className='container2-cm'>
              <div className='con2-div-cm'>
                <div className='cam-cred-cm'>
                  <label className='text-doted con1-label-cm'>Enter IP Address </label>
                  <input
                    type="text"
                    className="form-control ip-camera-cm ip-cam-cm"
                    id="ipaddress"
                    name="ip_address"
                    placeholder='Enter IP Address'
                    maxLength={100}
                    value={formData.ip_address}
                    onChange={handleChange}
                    disabled={disabledConnect}
                    required
                  />
                </div>
                <div className='cam-cred-cm'>
                  <label className='text-doted con1-label-cm'>Enter username </label>
                  <input
                    type="text"
                    className="form-control ip-camera-cm ip-cam-cm"
                    id="camusername"
                    name="user_name"
                    placeholder='Enter username'
                    maxLength={100}
                    value={formData.user_name}
                    onChange={handleChange}
                    disabled={disabledConnect}
                    required
                  />
                </div>
                <div className='cam-cred-cm'>
                  <label className='text-doted con1-label-cm'>Enter password </label>
                  <input
                    type="text"
                    className="form-control ip-camera-cm ip-cam-cm"
                    id="rtsp"
                    name="password"
                    placeholder='Enter password'
                    value={formData.password}
                    onChange={handleChange}
                    disabled={disabledConnect}
                    required
                  />
                </div>
              </div>
            </div>
            <div className='div-hr'><hr className='hr' /><span className='or'>or</span><hr className='hr' /></div>
            <div className='container2-cm'>
              <label className='text-doted con1-label-cm'>Enter RTSP code </label>
              <input
                type="text"
                className="form-control ip-camera-cm"
                id="rtsp"
                name="rtsp_url"
                placeholder='Enter RTSP code'
                maxLength={100}
                value={formData.rtsp_url}
                disabled={disabledConnect}
                onChange={handleChange} // Add onChange
                required
              />
            </div>
            <div className='container4-cm'>
              <button onClick={closePopup} className='add-cambtn-common cancel-cm'>Cancel</button>
              <button onClick={() => { ValidateCameraDatas() }} className='add-cambtn-common save-cm' disabled={disabledSave} >Save</button>
              <button onClick={() => { ValidateConnection() }} className='add-cambtn-common connect-cm' disabled={disabledConnect} >Connect</button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditPagePopup;
