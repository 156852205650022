import React from "react";
import "../assets/styles/plan-request.css";

function App() {
    return (
        <div className="FullWidth main-containerplan">
            <div className="plans-container">
                <div className="current-plan">
                    <div className="FullWidth FullHeight common-plan">
                        <h3>Current plan</h3>
                        <p>Total no of tools - <strong>08</strong></p>
                        <p className="config-link">Camera configuration - XXXXXXXX</p>
                        <div>
                            <p>Purchase date - <span>24 Nov 2024</span></p>
                            <p>Expiry date - <span>24 Nov 2025</span></p>
                            <hr />
                            <ul>
                                {["Facial Attendance", "Gender classification", "Unknown detection", "People In / Out", "Crowd Detection", "Vehicle In / Out", "ANPR", "Person tracking"].map((item) => (
                                    <li key={item}>
                                        {item} : <span>02</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <button>Upgrade</button>
                    </div>
                </div>
                <div className="upgrade-plan">
                    <div className="FullWidth FullHeight common-plan">
                        <h3>Upgrade plan</h3>
                        <p className="m-0">Add additional services to your existing plan.</p>
                        <ul>
                            {[
                                { label: "Facial attendance", count: 2 },
                                { label: "Gender classification", count: 2 },
                                { label: "Unknown tracking", count: 2 },
                                { label: "People In / Out", count: 2 },
                                { label: "Crowed detection", count: 2 },
                                { label: "Trespass detection", count: 0 },
                                { label: "Tripwire detection", count: 0 },
                                { label: "Vehicle In / Out", count: 2 },
                                { label: "ANPR", count: 2 },
                                { label: "PPE detection", count: 0 },
                                { label: "Person tracking", count: 2 },
                            ].map(({ label, count }) => (
                                <li key={label}>
                                    <input type="checkbox" />
                                    <span>{label}</span>
                                    <input type="number" min="0" value={count} className="input-number" />
                                    <span className="count">: {count < 10 ? `0${count}` : count}</span>
                                </li>
                            ))}
                        </ul>
                        <button>Send request</button>
                    </div>
                </div>
            </div>
            <div className="footer-div-pr">
                <p className="note">
                    Please note - Each AI tool requires specific camera specifications for optimal performance.
                    If the selected tools do not match your current hardware setup, please contact our support
                    team for assistance in updating your configuration or selecting compatible tools.
                </p>
            </div>
        </div>
    );
}

export default App;
