import React, { useRef, useEffect, useState } from "react";
import delete_point from '../assets/images/delete_point.svg';
import rule_clear from '../assets/images/rule_clear.svg';

const CanvasWithSelectablePoints = ({ initialPoints, width, height, onPointsChange }) => {
    const canvasRef = useRef(null);
    const [points, setPoints] = useState(initialPoints);
    const [selectedPointIndex, setSelectedPointIndex] = useState(null);
    const [imageHieght, setImageHieght] = useState(width);
    const [imageWidth, setImageWidth] = useState(height);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);

        setImageHieght(canvas.height);
        setImageWidth(canvas.width);

        // Draw points
        points.forEach(({ x, y }, index) => {
            context.beginPath();
            context.arc(x, y, 5, 0, 2 * Math.PI);
            context.fillStyle = selectedPointIndex === index ? "green" : "red";
            context.fill();
        });

        // Draw polygon if there are at least 2 points
        if (points.length >= 2) {
            context.beginPath();
            context.strokeStyle = "blue";
            points.forEach(({ x, y }, index) => {
                if (index === 0) {
                    context.moveTo(x, y);
                } else {
                    context.lineTo(x, y);
                }
            });
            context.closePath();
            context.stroke();
        }
    }, [points, selectedPointIndex]);

    const handleCanvasClick = (event) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();

        // Get mouse position relative to canvas
        const clickX = event.clientX;
        const clickY = event.clientY;

        // Map to canvas coordinates
        const canvasX = (clickX - rect.left) * (canvas.width / rect.width);
        const canvasY = (clickY - rect.top) * (canvas.height / rect.height);

        // Check if a point was clicked
        const clickedPointIndex = points.findIndex(
            ({ x, y }) => Math.hypot(x - canvasX, y - canvasY) <= 10
        );

        if (clickedPointIndex !== -1) {
            setSelectedPointIndex(clickedPointIndex);
        } else {
            setPoints((prevPoints) => {
                const newPoints = [...prevPoints, { x: canvasX, y: canvasY }];
                updatePoints(newPoints);
                return newPoints;
            });
        }
    };

    const handleDeletePoint = () => {
        if (selectedPointIndex !== null) {
            setPoints((prevPoints) => {
                const updatedPoints = prevPoints.filter((_, index) => index !== selectedPointIndex);
                updatePoints(updatedPoints);
                return updatedPoints;
            });
            setSelectedPointIndex(null);
        }
    };

    const handleClearAllPoints = () => {
        setPoints([]);
        updatePoints([]);
        setSelectedPointIndex(null);
    };

    const updatePoints = (newPoints) => {
        onPointsChange(newPoints, imageHieght, imageWidth);
    };

    return (
        <div>
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
                className="canvas-select-sc"
                onClick={handleCanvasClick}
            />
            <div style={{ marginTop: "10px" }}>
                <div className="bottom-part-cv">
                    <p className="message-para">*You can draw rule set directly on this image.</p>
                    <div className="d-flex">
                        <button
                            onClick={handleDeletePoint}
                            disabled={selectedPointIndex === null}
                            className="canvas-dlt-btn"
                        >
                            <img src={delete_point} alt="delete_point" className="delete_point-cs" />
                            Delete Selected Point
                        </button>
                        <button onClick={handleClearAllPoints} className="canvas-dlt-btn canvas-clear-btn">
                            <img src={rule_clear} alt="rule_clear" className="delete_point-cs" />
                            Clear All Points
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CanvasWithSelectablePoints;
