import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import face_scan from '../assets/images/face_scan.gif';
import add_members_icon from '../assets/images/add_members_icon.svg';
import config from '../config.js';
import img_upload from '../assets/images/img_upload.svg';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import axios from 'axios';


const AddEditMembers = ({ closePopup, type, RowData }) => {

  const [images, setImages] = useState([]);
  const [viewImages, setViewImages] = useState([]);
  const [formData, setFormData] = useState({
    members_id: RowData.members_id || '',
    members_name: RowData.members_name || '',
    department: RowData.department || '',
    mobile: RowData.mobile || '',
    img_1: RowData.img_1 || '',
    img_2: RowData.img_2 || '',
    img_3: RowData.img_3 || ''
  });
  const [initialData, setInitialData] = useState(formData);


  const findChanges = () => {
    const changes = Object.entries(formData)
      .filter(([key, newValue]) => newValue !== initialData[key])
      .map(([key, newValue]) => ({ key, newValue, members_id: RowData.members_id }));

    return changes;
  };

  useEffect(() => {
    const initialImages = [];

    if (RowData.img_1) initialImages.push(RowData.img_1);
    if (RowData.img_2) initialImages.push(RowData.img_2);
    if (RowData.img_3) initialImages.push(RowData.img_3);

    setImages(initialImages);
    setViewImages(initialImages);

    setInitialData({
      members_id: RowData.members_id || '',
      members_name: RowData.members_name || '',
      department: RowData.department || '',
      mobile: RowData.mobile || '',
      img_1: RowData.img_1 || '',
      img_2: RowData.img_2 || '',
      img_3: RowData.img_3 || ''
    });
  }, [RowData]);

  // Updated handleImageChange function
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    let iDx = files.length + images.length;

    if (files.length + images.length > 3) {
      toast.warning("You can only upload up to 3 images.");
      return;
    }

    const newImages = [];
    const newViewImages = [];
    const today = new Date();
    const formattedDate = `${today.getMonth() + 1}_${today.getDate()}_${today.getFullYear()}`;

    files.forEach((file, index) => {
      const imageURL = formattedDate + "_" + file.name;
      const viewImageURL = URL.createObjectURL(file);

      newImages.push(imageURL);
      newViewImages.push(viewImageURL);
    });

    setImages((prevImages) => [...prevImages, ...newImages]);
    setViewImages((prevImages) => [...prevImages, ...newViewImages]);

    const uploadPromises = files.map((item) => uploadMembersImageINS3(item, iDx));
    await Promise.all(uploadPromises);
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = prevImages?.filter((_, i) => i !== index) || [];
      return updatedImages;
    });

    setViewImages((prevImages) => {
      const updatedViewImages = prevImages?.filter((_, i) => i !== index) || [];
      for (let i = 0; i < 3; i++) {
        const newKey = `img_${i + 1}`;
        const objPrevImages = updatedViewImages[i] !== undefined ? updatedViewImages[i] : '';
        handleChange({ target: { name: newKey, value: objPrevImages } });
      }
      return updatedViewImages;
    });


  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const ValidateMemberData = async () => {
    if (formData !== null || formData !== "") {
      if (formData.members_name === "") {
        toast.error("Enter member's name");
        return;
      }

      if (formData.department === "") {
        toast.error("Enter member's department");
        return;
      }

      if (formData.mobile === "") {
        toast.error("Enter your member's phone number");
        return;
      }

      if (images.length === 0) {
        toast.error("You must upload atleast a image");
        return;
      }

      AddMemberFunc();

    }
  };

  const uploadMembersImageINS3 = async (imageFile, idx) => {
    try {
      const s3Client = new S3Client({
        region: 'ap-south-1',
        credentials: {
          accessKeyId: "AKIAZI2LDEUZWO2YXBHO",
          secretAccessKey: "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"
        }
      });

      const bucketName = 'smart-eye-cctv';
      const key = `members/${imageFile.name}`;
      const mimeType = imageFile.type || 'image/jpeg';

      const command = new PutObjectCommand({
        Bucket: bucketName,
        Key: key,
        ContentType: mimeType,
        Body: imageFile,
        ACL: 'public-read'
      });

      const signedUrl = await getSignedUrl(s3Client, command, { expiresIn: 3600 });

      const uploadResponse = await axios.put(signedUrl, imageFile, {
        headers: {
          'Content-Type': mimeType
        }
      });

      if (uploadResponse.status === 200) {
        const imageUrlS3 = `https://${bucketName}.s3.ap-south-1.amazonaws.com/${key}`;
        console.log('Image successfully uploaded to S3:', imageUrlS3);
        const imgKey = idx > 1 ? `img_${idx}` : 'img_1';
        handleChange({ target: { name: imgKey, value: imageUrlS3 } });

      } else {
        console.error('Failed to upload image to S3:', uploadResponse.status);
      }

    } catch (error) {
      console.error("Failed to upload image:", error);
      toast.error("Failed to upload image:", error);
    }
  };

  const AddMemberFunc = async () => {
    let objAPI = "";
    let objDatas = {};

    if (type === "Edit") {
      const changedColumns = findChanges();

      if (changedColumns.length === 0) {
        toast.warning("No changes found.");
        return;
      }
      else {
        objDatas = changedColumns[0];
        objAPI = "UpdateMemberDatas";
      }
    }
    else if (type === "Add") {
      objAPI = "InsertMemberDatas";
      objDatas = formData;
    }

    try {
      const response = await fetch(`${config.apiBaseUrl}${objAPI}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(objDatas),
      });

      if (!response.ok) {
        const errorData = await response.json();

        if (errorData.message.code === 'ER_DUP_ENTRY') {
          toast.error(errorData.message.sqlMessage);
        } else {
          toast.error("Error fetching data");
        }
        return;
      }

      const result = await response.json();
      console.log(result);

      let message = type === "Add" ? "Member added successfully" : "Member updated successfully";
      toast.success(message);

      const pageUpdates = `${config.apiBaseUrlPy}pageupdates/members/${type}/${result[0].members_id}`;
      axios.post(pageUpdates).catch((error) => {
        console.error('Error calling API:', error);
      });

      setTimeout(() => {
        closePopup();
        setFormData({
          members_id: '',
          members_name: '',
          department: '',
          mobile: '',
          img_1: '',
          img_2: '',
          img_3: '',
        });
      }, 3000);

    } catch (error) {
      console.error('Request failed:', error);
      toast.error('An error occurred');
    }

  };


  return (
    <Dialog open={true} onClose={closePopup} className='adedit-popup-mm'
      sx={{
        "& .MuiDialog-paper": {
          height: "76%",
          width: "70%",
          maxWidth: "70%",
        },
      }} >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
      <DialogTitle>
      </DialogTitle>
      <DialogContent>
        <div className='body-addpopup-cm d-flex'>
          <div className='col-6 facescan-container-am'>
            {formData.img_1 !== '' && type === "Edit" ? (
              <img src={RowData.img_1} alt='members_profile' className='add_members_icon' />
            ) : (
              <img src={face_scan} alt='face_scan' className='face-scan-am' />
            )}
          </div>
          <div className='col-6 addedit-container-am FullWidth FullHeight'>
            <div className='d-flex mb-4 modal-header-am'>
              <div className='col-2 addedit-img-am'>
                <img src={add_members_icon} alt='add_members_icon' className='add_members_icon' />
              </div>
              <div className='col-10 addedit-con-am'>
                <h5 className='title-am text-doted'>{type === "Add" ? "Add new members" : "Edit new members"}</h5>
                <p className='sub-title-am text-doted'>Add all details given below</p>
              </div>
            </div>
            <div className='addedit-bodycon-am'>
              <div>
                <label className='text-doted con1-label-cm'>Enter member name</label>
                <input type='text' className="form-control ip-camera-cm ip-cam-am" id="members_name" name="members_name" placeholder='Enter member name'
                  value={formData.members_name} onChange={handleChange} required />
              </div>
              <div>
                <label className='text-doted con1-label-cm'>Enter member department </label>
                <input type='text' className="form-control ip-camera-cm ip-cam-am" id="department" name="department" placeholder='Enter member department '
                  value={formData.department} onChange={handleChange} required />
              </div>
              <div>
                <label className='text-doted con1-label-cm'>Enter member contact number </label>
                <input type='text' className="form-control ip-camera-cm ip-cam-am" id="mobile" name="mobile" placeholder='Enter member contact number '
                  value={formData.mobile} onChange={handleChange} maxLength="10"
                  pattern="[0-9]{10}"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, '');
                  }} required />
              </div>
              <div>
                <div className='d-flex'>

                  <div className="form-group file-area">
                    <input type="file" name="images" id="images" onChange={(e) => handleImageChange(e)} accept=".jpg, .jpeg, .png, .svg, .avif" required="required" multiple="multiple" />
                    <div className="file-dummy">
                      <img src={img_upload} alt='img_upload' className='img_upload_am' />
                      <div className="default">Upload image</div>
                    </div>
                  </div>
                  <p className='img-count-am'>{images.length}/3 </p>
                </div>

                <div className='place-imgcon-am'>
                  {viewImages.map((image, index) => (
                    <div key={index} className='uploaded-img-am'>
                      <img src={image} alt={`Uploaded ${index + 1}`} className='upl-img-am' />
                      <button
                        onClick={() => handleRemoveImage(index)}
                        className='img_upload_file_am'
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='footer-addedit-am'>
              <button onClick={closePopup} className='add-cambtn-common cancel-cm'>Cancel</button>
              <button className='add-cambtn-common save-cm' onClick={ValidateMemberData} >Add member</button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditMembers;
